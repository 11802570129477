import React, { useEffect, useState } from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from "react-redux";
import { register } from "../../pages/store";
//images
import closeImg from "../../assets/img/play-icon.png"

const defaultValues = {
  wallet: '',
  username: '',
  referenceCode: ''
}

const Register = () => {
  const [display, setDisplay] = useState(true)

  const { currentUser, wallet } = useMetaMask()

  const dispatch = useDispatch();

  // ** Vars
  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  useEffect(() => {
    setDisplay(true)
    setValue('wallet', wallet.accounts[0])
  }, [currentUser, wallet])

  // ** Function to handle form submit
  const onSubmit = data => {
    if (Object.values(data).every(field => field.length > 0)) {
      dispatch(register({ data }))
      .then((result) => {
        console.log(result)
      }).catch((err) => {
        
      });
    } else {
      for (const key in data) {
        if (data[key] !== null && data[key].length === 0) {
          setError(key, {
            type: 'manual'
          })
        }
      }
    }
  }

  return (
    <>
      { currentUser === null && display &&
        <div className="popup-register popup">
          <Link onClick={() => setDisplay(!display)} className="close-btn">
            <img src={closeImg} className="w-100" alt="" />
          </Link>
          <div className="in">
            {/* TODO : Register bg png dosyası yok */}
            {/* <img src="img/register-bg.png" className="w-100" alt="" /> */}
            <form onSubmit={handleSubmit(onSubmit)} >
              <div className="box-inner-area">
                <div className="form-left">
                  <div className="container">
                    <div className="col-lg-12 formItem">
                      <span>WALLET ADDRESS:</span>
                      <div className="input">
                        <Controller
                          name='wallet'
                          control={control}
                          render={({ field }) => (
                            <input id='wallet' disabled={true} placeholder='0x00' invalid={errors.wallet && true} {...field} />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 formItem">
                      <span>USERNAME:</span>
                      <div className="input">
                        <Controller
                          name='username'
                          control={control}
                          render={({ field }) => (
                            <input id='username' placeholder='Write your username' invalid={errors.username && true} {...field} />
                          )}
                        />
                        <img
                          className="icon-warning"
                          alt=""
                        />
                      </div>
                      <small className="note">
                      </small>
                    </div>
                    <div className="col-lg-12 formItem">
                      <span>REFERENCE CODE:</span>
                      <div className="input">
                        <Controller
                          name='referenceCode'
                          control={control}
                          render={({ field }) => (
                            <input id='referenceCode' placeholder='Reference code' invalid={errors.referenceCode && true} {...field} />
                          )}
                        />          
                      </div>
                      <small className="note">
                        *optional
                      </small>
                    </div>
                  </div>
                </div>
                <div className="box-right-avatar">
                  <h4>CHOOSE YOUR AVATAR</h4>
                  <div className="avatar-inner">
                    {/* TODO : avatar sayısı artıkça avatarlar parçalar halinde gösterilebilir. carousel! */}
                  </div>
                </div>
                <div className="btns-bottom">
                  <button type='submit' className="btn-sub">
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      }
    </>
  );
};

export default Register;
