// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Contract, providers, utils, BigNumber } from "ethers";
import Bingo from "../../artifacts/contracts/Bingo.sol/Bingo.json";
import axios from "axios";

const dateTimeHost = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ap = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  if (hours === 12) {
    hours = 0;
  }
  let mergeTime =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    " " +
    ap;
  return mergeTime;
};

export const register = createAsyncThunk(
  "register",
  async (user, { dispatch, getState }) => {
    console.log(process.env.REACT_APP_API);
    await axios.post(`${process.env.REACT_APP_API}users/register`, user);
    return user;
  }
);

export const checkRole = createAsyncThunk("checkRole", async () => {
  const provider = new providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const bingoContract = new Contract(
    process.env.REACT_APP_CONTRACT_ADDRESS,
    Bingo.abi,
    signer
  );

  const accounts = await window.ethereum.request({
    method: "eth_accounts",
  });

  const admins = await bingoContract.admins(accounts[0]);
  const hosts = await bingoContract.hosts(accounts[0]);

  if (accounts[0] && admins) {
    return "admin";
  } else if (accounts[0] && hosts) {
    return "host";
  } else {
    return "user";
  }
});

// Host Panel
//TODO: code iyileştirmesi yapılmalı
export const fetchGamesHost = createAsyncThunk(
  "fetchGamesHost",
  async (params) => {
    const nowtimestamp = Math.floor(Date.now() / 1000);
    let gameCounter = Number(
      await params.contractAndAuth.contract.gameCounter()
    );
    console.log("gameCounter:", Number(gameCounter));
    let allGames = [];

    while (gameCounter > 0) {
      //let gameStatus = await contract.gameStatus(gameCounter);
      //console.log("gameStatus:", gameStatus);
      const games = await params.contractAndAuth.contract.games(gameCounter);
      const gametimestamp = Number(games[1]);
      const dateFormatGame = new Date(gametimestamp * 1000);

      if (
        nowtimestamp - gametimestamp >=
        params.contractAndAuth.expirationDuration
      )
        // (eski: 3600 * 3) oyun saati en sondan geriye doğru bakılırken 3 saati geçerse dögüden çıkar
        break;

      const gamecancelled = games[7]; //cancelled
      const gameSeed = Number(games[6]); // veya games.seed
      let gamecardprice = Number(
        (+utils.formatEther(BigNumber.from(games[3]))).toFixed(0)
      );

      if (nowtimestamp < gametimestamp && gamecancelled === false) {
        // or gameStatus === 1
        switch (gamecardprice) {
          case 2:
            allGames.push([
              gametimestamp,
              dateTimeHost(dateFormatGame),
              "BRONZE",
              gameCounter,
              1,
            ]);
            break;

          case 6:
            allGames.push([
              gametimestamp,
              dateTimeHost(dateFormatGame),
              "SILVER",
              gameCounter,
              1,
            ]);
            break;

          case 10:
            allGames.push([
              gametimestamp,
              dateTimeHost(dateFormatGame),
              "GOLD",
              gameCounter,
              1,
            ]);
            break;

          case 15:
            allGames.push([
              gametimestamp,
              dateTimeHost(dateFormatGame),
              "DIAMOND",
              gameCounter,
              1,
            ]);
            break;
        }
      }
      if (
        nowtimestamp > gametimestamp &&
        nowtimestamp <
          gametimestamp + params.contractAndAuth.expirationDuration &&
        gamecancelled === false
      ) {
        // or gameStatus === 2
        if (gameSeed === 0) {
          //no seed //else -> started games
          switch (gamecardprice) {
            case 2:
              allGames.push([
                gametimestamp,
                dateTimeHost(dateFormatGame),
                "BRONZE",
                gameCounter,
                2,
              ]);
              break;

            case 6:
              allGames.push([
                gametimestamp,
                dateTimeHost(dateFormatGame),
                "SILVER",
                gameCounter,
                2,
              ]);
              break;

            case 10:
              allGames.push([
                gametimestamp,
                dateTimeHost(dateFormatGame),
                "GOLD",
                gameCounter,
                2,
              ]);
              break;

            case 15:
              allGames.push([
                gametimestamp,
                dateTimeHost(dateFormatGame),
                "DIAMOND",
                gameCounter,
                2,
              ]);
              break;
          }
        }
      }

      gameCounter--;
    }

    allGames.sort();
    console.log("fetchGamesHost:", allGames);
    return allGames;
  }
);

// Host Panel
//TODO: code iyileştirmesi yapılmalı
export const createGameTimes = createAsyncThunk("createGameTimes", async () => {
  const nowtimestamp = Math.floor(Date.now() / 1000);
  let i = 5;
  let selectOptions = [];
  while (true) {
    if (
      new Date((nowtimestamp + 60 * i) * 1000).getDate() ===
      new Date(nowtimestamp * 1000).getDate()
    ) {
      //gün değişmedikçe döner
      selectOptions.push(
        dateTimeHost(new Date((nowtimestamp + 60 * i) * 1000))
      );
      i++;
    } else {
      break;
    }
  }
  return selectOptions;
});

export const checkGameStatus = createAsyncThunk("checkGameStatus", async (params) => {
  const jammyWon = (await params.contractAndAuth.contract.gamePrizes(params.gameId, 0))[1];
  const game = await params.contractAndAuth.contract.games(params.gameId);
  const nowTimestamp = Math.floor(Date.now() / 1000);
  const gameTimestamp = Number(game[1]);
  const gameCancelled = game[7];
  const gameSeed = game[6];

  if (gameTimestamp === 0) { //GameStatus.INVALID
    return 0;
  }
  if (jammyWon) { //GameStatus.ENDED
    return 4;
  }
  if (gameCancelled) { //GameStatus.CANCELLED
    return 6;
  }
  if (nowTimestamp < gameTimestamp) { //GameStatus.CREATED
    return 1;
  }
  if (nowTimestamp > gameTimestamp && nowTimestamp < (gameTimestamp + params.contractAndAuth.expirationDuration)) {
    if (gameSeed > 0) { //GameStatus.STARTED
      return 3;
    }
    return 2; //GameStatus.READY
  }
  return 5; //GameStatus.EXPIRED
});

export const rootStore = createSlice({
  name: "rootStore",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkGameStatus.fulfilled, (state, action) => {
      state.gameStatus = action.payload;
    });
  },
});

export default rootStore.reducer;
