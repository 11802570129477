// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.connect-head-btn {
    position: absolute;
    right: 1%;
    top: 0%;
  }
  
  .connect-head-btn span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #e3f8ff;
    font-weight: 500;
    font-size: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/ConnectWallet/ConnetWallet.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;EACT;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,cAAc;IACd,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":["\n.connect-head-btn {\n    position: absolute;\n    right: 1%;\n    top: 0%;\n  }\n  \n  .connect-head-btn span {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    color: #e3f8ff;\n    font-weight: 500;\n    font-size: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
