// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-warning .in {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 9;
  width: 100%;
  max-width: 1230px;
}
.popup-warning .in .text {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  padding: 0 10%;
  font-size: 24px;
  transform: translate(-50%, -50%);
  text-align: center;
}
.popup-warning .in .title {
  position: absolute;
  left: 50%;
  top: 28%;
  width: auto;
  padding: 0 5%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.popup-warning .in .title span {
  font-size: calc(12px + 50%);
  position: relative;
  z-index: 10;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}
.popup-warning .in .title img {
  position: absolute;
  width: 100%;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.popup-warning .btns-bottom {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 60%;
    z-index: 10;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Warning/Warning.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,gCAAgC;EAChC,QAAQ;EACR,UAAU;EACV,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,UAAU;EACV,cAAc;EACd,eAAe;EACf,gCAAgC;EAChC,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,WAAW;EACX,aAAa;EACb,gCAAgC;EAChC,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;EACX,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,SAAS;EACT,gCAAgC;EAChC,QAAQ;AACV;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,6BAA6B;IAC7B,QAAQ;IACR,WAAW;EACb","sourcesContent":[".popup-warning .in {\n  position: fixed;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  top: 50%;\n  z-index: 9;\n  width: 100%;\n  max-width: 1230px;\n}\n.popup-warning .in .text {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  width: 80%;\n  padding: 0 10%;\n  font-size: 24px;\n  transform: translate(-50%, -50%);\n  text-align: center;\n}\n.popup-warning .in .title {\n  position: absolute;\n  left: 50%;\n  top: 28%;\n  width: auto;\n  padding: 0 5%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n}\n.popup-warning .in .title span {\n  font-size: calc(12px + 50%);\n  position: relative;\n  z-index: 10;\n  transform: translate(-50%, -50%);\n  text-transform: uppercase;\n}\n.popup-warning .in .title img {\n  position: absolute;\n  width: 100%;\n  z-index: 9;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  top: 50%;\n}\n.popup-warning .btns-bottom {\n    position: absolute;\n    left: 50%;\n    transform: translate(-50%, 0);\n    top: 60%;\n    z-index: 10;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
