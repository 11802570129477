// ** Router imports
import { useRoutes } from 'react-router-dom'
// ** Utils
import Lobby from '../pages/Lobby'
import Error from '../pages/Error'
import getRoutes from './routes'
import Join from '../pages/Join/index'
import Game from '../pages/Game/index'
import Panel from '../pages/Panel/index'

const Router = () => { 

  const allRoutes = getRoutes()

  const routes = useRoutes([
    {
      path: '/',
      index: true,
      element: <Lobby />
    },
    {
      path: '/join',
      index: true,
      element: <Join />
    },
    {
      path: '/game',
      index: true,
      element: <Game />
    },
    {
      path: '/panel',
      index: true,
      element: <Panel />
    },
    {
      path: '*',
      element: <Error />
    },
    ...allRoutes
  ])

  return routes
}

export default Router
