import React, { useEffect, useState } from "react";
import { utils } from "ethers";
import { getCard, cardRedraw } from "../../../pages/Game/store";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
//images
import logo from "../../../assets/img/logo.svg";

const Card2 = ({
  game,
  gameStatus,
  gameId,
  contractAndAuth,
  wallet,
  inComponent,
  hash,
  checkDrawCard,
  checkReDrawCard,
  setCheckReDrawCard,
  drawnNumbers,
}) => {
  console.log(`#-> Card2 rendered (${inComponent})`);
  const dispatch = useDispatch();
  const cardCount = useSelector((state) => state.gameStore.cardCount);
  const [isLoading, setIsLoading] = useState(false);
  const [cardIndex, setCardIndex] = useState(null);
  const [cardNumbers, setCardNumbers] = useState([]);
  const playerCardIndex = 2;

  const redrawCards = async (playerCardIndex) => {
    if (gameStatus === 1) {
      const redrawReqId =
        localStorage.getItem(
          `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
        ) !== undefined
          ? localStorage.getItem(
              `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
            )
          : null;

      if (redrawReqId === null) {
        try {
          const tx = await contractAndAuth.contract.redrawCard(
            gameId,
            playerCardIndex,
            { value: utils.parseEther((Number(game[2]) / 2).toString()) }
          );
          const receipt = await tx.wait();
          console.log("receipt-redrawCard:", receipt);
          setCardIndex(null);
          setCardNumbers([]);
          setCheckReDrawCard(0);
        } catch (error) {
          console.log("err:", error);
        }
      } else {
        dispatch(
          cardRedraw({
            gameId,
            contractAndAuth,
            wallet,
          })
        ).then((result) => {
          if (result.payload) {
            console.log("cardRedraw:", result.payload);
          }
        });
      }
    } else {
      console.log("redrawCards: wrongGameStatus");
    }
  };

  const closeNumber = (card, num) => {
    console.log("closed number:", card, num);
  };

  useEffect(() => {
    if (contractAndAuth.contract && cardCount >= 3) {
      gameStatus !== 3 && setIsLoading(true);
      dispatch(
        getCard({ gameId, contractAndAuth, wallet, playerCardIndex })
      ).then((result) => {
        setIsLoading(true);
        console.log("getCard(2):", result.payload, checkReDrawCard);
        if (!result.payload.errors) {
          setCardIndex(result.payload.cardIndex);
          setCardNumbers(result.payload.cardNumbers);
        } else if (result.payload.errors.includes("card not ready")) {
          setCardIndex(null);
          setCardNumbers([]);
          setCheckReDrawCard(0);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [contractAndAuth, checkDrawCard, checkReDrawCard, drawnNumbers]);

  return (
    <>
      {isLoading ? (
        <div className="item-card">
          <div className="d-flex w-100 h-100 justify-content-center align-items-center">
            <img src={logo} alt="" />
            <Loading text="Loading card..." />
          </div>
        </div>
      ) : cardIndex !== null ? (
        <div
          className="item-card"
          style={
            inComponent === "CardTx" || inComponent === "CardChange"
              ? { cursor: "pointer" }
              : { cursor: "auto" }
          }
          onClick={() =>
            inComponent === "CardChange"
              ? redrawCards(playerCardIndex)
              : inComponent === "CardTx"
              ? window.open(
                  `${process.env.REACT_APP_NETWORKURL}tx/${hash}`,
                  "_blank"
                )
              : null
          }
        >
          <span>{cardIndex}</span>
          <div className="in">
            {cardNumbers.length > 0
              ? cardNumbers.map((number, numberindex) => (
                  <div
                    key={numberindex}
                    className={
                      drawnNumbers
                        ? drawnNumbers.includes(number) || number === 0
                          ? "item revealed"
                          : "item"
                        : "item"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      number !== 0 &&
                      inComponent !== "CardTx" &&
                      inComponent !== "CardChange"
                        ? closeNumber(0, number)
                        : null
                    }
                  >
                    {number !== 0 ? number : null}
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : (
        <div
          className="item-card"
          style={
            inComponent === "CardTx" ||
            inComponent === "CardChange" ||
            inComponent === "Cards"
              ? { cursor: "pointer" }
              : { cursor: "auto" }
          }
          onClick={() =>
            inComponent === "CardChange"
              ? redrawCards(playerCardIndex)
              : inComponent === "CardTx"
              ? window.open(
                  `${process.env.REACT_APP_NETWORKURL}tx/${hash}`,
                  "_blank"
                )
              : inComponent === "Cards" &&
                checkReDrawCard === 0 &&
                cardCount >= 3
              ? redrawCards(playerCardIndex)
              : null
          }
        >
          <div className="d-flex w-100 h-100 justify-content-center align-items-center">
            <img src={logo} alt="" />
            {checkReDrawCard === 0 && cardCount >= 3 && (
              <Loading text="waiting card..." />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Card2);
