import React, { useEffect, useState } from "react";
import "./PopupsGameStyle.css";
import CloseButton from "../../CloseButton/CloseButton";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { hexToArry } from "../../CreateCards";
//images
import profileImg from "../../../assets/img/profil-image.png";
import facebookImg from "../../../assets/img/facebook.png";
import twitterImg from "../../../assets/img/twitter.png";
import invite2Img from "../../../assets/img/invite2.png";
import popupBgImg from "../../../assets/img/popup-bg.png";
import logo from "../../../assets/img/logo.svg";

const WinnerLoserPopup = ({ contractAndAuth, wallet, gameId, drawnNumbers, onClose }) => {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isWin, setIsWin] = useState(null);
  const [winCard, setWinCard] = useState([]);

  const winnersImages = require.context(
    "../../../assets/img/winpopupheaders",
    true
  );

  const winnersImageList = winnersImages
    .keys()
    .map((image) => winnersImages(image));

  const losersImages = require.context(
    "../../../assets/img/losepopupheaders",
    true
  );

  const losersImageList = losersImages
    .keys()
    .map((image) => losersImages(image));

  const generate_random_number = (max) => Math.floor(Math.random() * max);

  const checkWinLoser = async () => {
    try {
      for (let i = 0; ; i++) {
        if (
          wallet.accounts[0].toLowerCase() ===
          (
            await contractAndAuth.contract.prizeWinners(gameId, 0, i)
          ).toLowerCase()
        ) {
          for (let k = 0; k < 4; k++) {
            //k playercardindex
            try {
              const playerCardHex = await contractAndAuth.contract.playerCards(
                gameId,
                wallet.accounts[0].toLowerCase(),
                k
              );
              const playerCard = hexToArry(playerCardHex);
              const rowsToMatch = 5;
              let matchedRows = 0;
    
              let seenNum = 0;
              let rowIndex = 0;
              let winRowIndex = 0;
              playerCard.forEach((cardNum, cardNumIndex) => {
                if (cardNum === 0) seenNum++;
                drawnNumbers.forEach((drawnNum) => {
                  //TODO: includes kullan
                  if (Number(cardNum) === Number(drawnNum)) {
                    seenNum++;
                  }
                });
                if (cardNumIndex % 5 === 4) {
                  if (seenNum === 5) {
                    matchedRows++;
                    winRowIndex = rowIndex;
                  }
                  seenNum = 0;
                  rowIndex++;
                }
              });
    
              if (matchedRows >= rowsToMatch) {
                console.log("winplayercardindex:", k, "winRowIndex:", winRowIndex);
                //TODO: Kart uzunluğu apiden alınacak (200)
                let cardIndex = 0;
                for (cardIndex; cardIndex < 200; cardIndex++) {
                  const cards = await contractAndAuth.contract.cards(cardIndex);
                  if (cards._hex === playerCardHex._hex) {
                    break;
                  }
                }
                setWinCard([cardIndex, playerCard]);
                setIsWin(1);
                setIsLoading(false);
                break; // ilgili kullancıya ait ilk jam yapan kartı bulunca cıkar. birden fazla kartı o jam i yapmış olabilir
              }
            } catch (error) {
              console.log(error.reason);
              setIsLoading(false);
              break;
            }
          }
        }
      }
    } catch (error) {
      console.log(">>> isWin:", error.reason);
      setIsWin(0);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    checkWinLoser();
  }, [contractAndAuth]);

  return (
    <>
      <div className="popup-wrapper">
        <CloseButton onClose={onClose} to={search} />
        <div className="in">
          {/* üstteki değişecek alan */}
          {isWin ? (
            <div className="text-top-area">
              <img
                src={
                  winnersImageList[
                    generate_random_number(winnersImageList.length)
                  ]
                }
                alt=""
              ></img>
            </div>
          ) : (
            <div className="text-top-area">
              <img
                src={
                  losersImageList[
                    generate_random_number(losersImageList.length)
                  ]
                }
                alt=""
              ></img>
            </div>
          )}

          <div className="card-bottom-area">
            <div className="card-container">
              {isLoading ? (
                <div className="item-card">
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                    <img src={logo} alt="" />
                    <Loading text="Loading card..." />
                  </div>
                </div>
              ) : winCard.length > 0 ? (
                <div className="item-card">
                  <span>{winCard[0]}</span>
                  <div className="in">
                    {winCard[1].map((numbers, numberindex) => (
                      <div
                        className={numbers === 0 ? "item revealed" : "item"}
                        key={numberindex}
                      >
                        {numbers !== 0 ? numbers : null}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="item-card">
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                    <img src={logo} alt="" />
                  </div>
                </div>
              )}
              <div className="right-area">
                <div className="profile-info-box">
                  <div className="img">
                    <img src={profileImg} alt="" />
                  </div>
                  {/* TODO : Username ve Wallet propsdan çekilecek */}
                  <div className="right">
                    <span>Jammy User # 0</span>
                    <small>{wallet.accounts[0]}</small>
                  </div>
                </div>
                <div className="buttons">
                  <Link to={search}>
                    <img src={facebookImg} alt="" />
                    <span>Share</span>
                  </Link>
                  <Link to={search}>
                    <img src={twitterImg} alt="" />
                    <span>Tweet</span>
                  </Link>
                  <Link to={search}>
                    <img src={invite2Img} alt="" />
                    <span>Invite</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <img src={popupBgImg} className="popup-bg" alt="" />
        </div>
      </div>
    </>
  );
};

export default WinnerLoserPopup;
