/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, createContext, useContext, useCallback } from 'react'
import detectEthereumProvider from '@metamask/detect-provider'
import { formatBalance } from '../Utils'
import { Contract, providers } from "ethers";
import Bingo from "../../artifacts/contracts/Bingo.sol/Bingo.json";
import VRF from "../../artifacts/contracts/Mocks/MockCoordinator.sol/MockCoordinator.json";

const disconnectedState = { accounts: [], balance: '', chainId: '' }
const defContractAndAuthState = { 
  contract: null,
  vrf: null,
  isAdmin: false,
  isHost: false,
  expirationDuration: 0,
  precisionBasis: 0,
  contractBalance: null
}

const MetaMaskContext = createContext()

export const MetaMaskContextProvider = ({ children }) => {
  const [hasProvider, setHasProvider] = useState(null)
  const [isConnecting, setIsConnecting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const clearError = () => setErrorMessage('')
  const [wallet, setWallet] = useState(disconnectedState)
  const [contractAndAuth, setContractAndAuth] = useState(defContractAndAuthState)

  // useCallback ensures that you don't uselessly recreate the _updateWallet function on every render
  const _updateWallet = useCallback(async (providedAccounts) => {
    const accounts = providedAccounts || await window.ethereum.request(
      { method: 'eth_accounts' },
    )

    if (accounts.length === 0) {
      // If there are no accounts, then the user is disconnected
      setWallet(disconnectedState)
      sessionStorage.clear() // bağlantı kesilir veya mm kilitlenirse undefined döner
      setContractAndAuth(defContractAndAuthState)
      return
    }

    //TODO: Balance düzeltilecek
    const balance = formatBalance(await window.ethereum.request({
      method: 'eth_getBalance',
      params: [accounts[0], 'latest'],
    }))

    const chainId = await window.ethereum.request({
      method: 'eth_chainId',
    })

    setWallet({ accounts, balance, chainId })
    sessionStorage.setItem("accountMM", accounts[0]);

    // set Contract & Auths
    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const bingoContract = new Contract(process.env.REACT_APP_CONTRACT_ADDRESS, Bingo.abi, signer);
    const vrfContract = new Contract(process.env.REACT_APP_VRF_CONTRACT_ADDRESS, VRF.abi, signer);
    if (bingoContract) {
      setContractAndAuth({
        contract: bingoContract,
        vrf: vrfContract,
        isAdmin: await bingoContract.admins(accounts[0]),
        isHost: await bingoContract.hosts(accounts[0]),
        expirationDuration: Number(await bingoContract.EXPIRATION_DURATION()),
        precisionBasis: Number(await bingoContract.PRECISION_BASIS()),
        contractBalance: await provider.getBalance(process.env.REACT_APP_CONTRACT_ADDRESS)
      })
    }
    // set Contract & Auths
  }, [])

  const updateWalletAndAccounts = useCallback(() => _updateWallet(), [_updateWallet])
  const updateWallet = useCallback((accounts) => _updateWallet(accounts), [_updateWallet])

  useEffect(() => {
    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true })
      setHasProvider(Boolean(provider))

      if (provider) {
        updateWalletAndAccounts()
        window.ethereum.on('accountsChanged', updateWallet)
        window.ethereum.on('chainChanged', updateWalletAndAccounts)
      }
    }

    getProvider()

    return () => {
      window.ethereum?.removeListener('accountsChanged', updateWallet)
      window.ethereum?.removeListener('chainChanged', updateWalletAndAccounts)
    }
  }, [updateWallet, updateWalletAndAccounts])
  
  const connectMetaMask = async () => {
    setIsConnecting(true)

    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      clearError()
      updateWallet(accounts)

    } catch (err) {
      setErrorMessage(err.message)
    }
    setIsConnecting(false)
  }

  return (
    <MetaMaskContext.Provider
      value={{
        wallet,
        contractAndAuth,
        hasProvider,
        error: !!errorMessage,
        errorMessage,
        isConnecting,
        connectMetaMask,
        clearError,
      }}
    >
      {children}
    </MetaMaskContext.Provider>
  )
}

export const useMetaMask = () => {
  const context = useContext(MetaMaskContext)
  if (context === undefined) {
    throw new Error('useMetaMask must be used within a "MetaMaskContextProvider"')
  }
  return context
}