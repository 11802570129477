import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getActiveGames } from "./store";
import { useDispatch } from "react-redux";
import "./index.css";
import BottomLine from "../../components/BottomLine/BottomLine";
import Carousel from "../../components/Carousel/Carousel";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import { Contract, providers } from "ethers";
import Bingo from "../../artifacts/contracts/Bingo.sol/Bingo.json";
//images
import logo2Img from "../../assets/img/logo2.svg";
import NextArrowImg from "../../assets/img/next.png";
import PrevArrowImg from "../../assets/img/prev.png";
import cardBgImg from "../../assets/img/card-bg.png";

const dateTimeLobby = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ap = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");
  let mergeTime = hours + "-" + ap;
  return mergeTime;
};

const activeTimeBeetween = () => {
  const dateFormatNow = new Date(Math.floor(Date.now()));
  const hours = dateTimeLobby(dateFormatNow).split("-")[0];
  const ap = dateTimeLobby(dateFormatNow).split("-")[1];
  let initialSlideIndex = 0;

  switch (Number(hours)) {
    case 12:
      if (ap === "am") {
        initialSlideIndex = 0;
      } else if (ap === "pm") {
        initialSlideIndex = 4;
      }
      break;

    case 1:
      if (ap === "am") {
        initialSlideIndex = 0;
      } else if (ap === "pm") {
        initialSlideIndex = 4;
      }
      break;

    case 2:
      if (ap === "am") {
        initialSlideIndex = 0;
      } else if (ap === "pm") {
        initialSlideIndex = 4;
      }
      break;

    case 3:
      if (ap === "am") {
        initialSlideIndex = 1;
      } else if (ap === "pm") {
        initialSlideIndex = 5;
      }
      break;

    case 4:
      if (ap === "am") {
        initialSlideIndex = 1;
      } else if (ap === "pm") {
        initialSlideIndex = 5;
      }
      break;

    case 5:
      if (ap === "am") {
        initialSlideIndex = 1;
      } else if (ap === "pm") {
        initialSlideIndex = 5;
      }
      break;

    case 6:
      if (ap === "am") {
        initialSlideIndex = 2;
      } else if (ap === "pm") {
        initialSlideIndex = 6;
      }
      break;

    case 7:
      if (ap === "am") {
        initialSlideIndex = 2;
      } else if (ap === "pm") {
        initialSlideIndex = 6;
      }
      break;

    case 8:
      if (ap === "am") {
        initialSlideIndex = 2;
      } else if (ap === "pm") {
        initialSlideIndex = 6;
      }
      break;

    case 9:
      if (ap === "am") {
        initialSlideIndex = 3;
      } else if (ap === "pm") {
        initialSlideIndex = 7;
      }
      break;

    case 10:
      if (ap === "am") {
        initialSlideIndex = 3;
      } else if (ap === "pm") {
        initialSlideIndex = 7;
      }
      break;

    case 11:
      if (ap === "am") {
        initialSlideIndex = 3;
      } else if (ap === "pm") {
        initialSlideIndex = 7;
      }
      break;
  }
  return initialSlideIndex;
};

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} src={NextArrowImg} onClick={onClick} alt="" />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} src={PrevArrowImg} onClick={onClick} alt="" />
  );
}

const Lobby = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contractAndAuth, wallet } = useMetaMask();
  const [activeGames, setActiveGames] = useState([]);

  //TODO: getActiveGames için memorization yapılmalı
  // useEffect(() => {
  //   document.title = "Lobby - Jammy";
  //   console.log("useEffect: Loaded Lobby");

  //   //TODO: dispatch'ler async olmalı
  //   if (contractAndAuth.contract) {
  //     dispatch(getActiveGames({ contractAndAuth })).then((result) => {
  //       setActiveGames(result.payload);
  //       console.log("activeGames:", result.payload);
  //     });

  //     contractAndAuth.contract.on("GameCreated", async (host, gameId) => {
  //       console.log("(Lobby): GameCreated event was emmited");
  //       dispatch(getActiveGames({ contractAndAuth })).then((result) => {
  //         setActiveGames(result.payload);
  //       });
  //     });

  //     contractAndAuth.contract.on("GameCancelled", async (gameId) => {
  //       console.log("(Lobby): GameCancelled event was emmited");
  //       dispatch(getActiveGames({ contractAndAuth })).then((result) => {
  //         setActiveGames(result.payload);
  //       });
  //     });
  //   } else {
  //     setActiveGames([]);
  //   }
  // }, [contractAndAuth]);

  useEffect(() => {
    // if (wallet.accounts.length === 0) {
      const contractAndAuth = { contract: null };
      const provider = new providers.Web3Provider(window.ethereum);
      const bingoContract = new Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        Bingo.abi,
        provider // imzalayan olmadan çağrılır
      );
      if (bingoContract) {
        (async () => {
          contractAndAuth.contract = bingoContract;
          dispatch(getActiveGames({ contractAndAuth })).then((result) => {
            if (result.payload) {
              setActiveGames(result.payload);
              console.log("activeGames (without wallet):", result.payload);
            }
          });

          contractAndAuth.contract.on("GameCreated", async (host, gameId) => {
            console.log("(Lobby): GameCreated event was emmited");
            dispatch(getActiveGames({ contractAndAuth })).then((result) => {
              setActiveGames(result.payload);
            });
          });
    
          contractAndAuth.contract.on("GameCancelled", async (gameId) => {
            console.log("(Lobby): GameCancelled event was emmited");
            dispatch(getActiveGames({ contractAndAuth })).then((result) => {
              setActiveGames(result.payload);
            });
          });
        })();
      }
    // }
  }, []);

  const handleClickRoom = async (room) => {
    if (room.gameCountPerTime > 0) {
      //TODO: navigate yönteminin doğru bir kullanım mı?
      navigate({
        pathname: "join",
        search: `?start=${room.timeBeetween.split("-")[0]}&end=${
          room.timeBeetween.split("-")[1]
        }&ap=${room.timeBeetween.split("-")[2]}`,
      });
    }
  };

  return (
    <>
      <Link className="logo-lobby">
        <img src={logo2Img} alt="" />
      </Link>

      <ConnectWallet />

      <div className="lobby-carousel-area">
        {/* TODO: loading eklenecek (önce loading sonra wallet bağlı ise carousel değilse please connect wallet olmalı. sayfa ilk yüklemelerinde wallet bağlı bile olmuş olsa önce bağlı görmediği için.) */}
        {activeGames.length > 0 ? (
          <Carousel
            centerMode={true}
            initialSlide={activeTimeBeetween()}
            infinite={true}
            slidesToShow={3}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            carouselData={activeGames.map((item, index) => (
              <div className="item" key={index}>
                <span className="time">{item.title}</span>
                <span className="game-number">
                  {item.gameCountPerTime} Active Games
                </span>
                <img
                  onClick={() => handleClickRoom(item)}
                  src={cardBgImg}
                  alt=""
                />
              </div>
            ))}
          />
        ) : (
          <h2 style={{ textAlign: "center" }}>Plese connect wallet!</h2>
        )}
      </div>

      <BottomLine />
    </>
  );
};

export default Lobby;
