import React, { useState } from "react";
import "./ConnectWallet2.css";
import { Link, useLocation } from "react-router-dom";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import UserProfile from "../UserProfile/UserProfile";
import { formatAddress } from "../../utility/Utils";
//images
import profileImg from "../../assets/img/profile-img.png";
import profileBgImg from "../../assets/img/profile-join-bg.png";

const ConnectWallet2 = () => {
  const { search } = useLocation();
  const { wallet, hasProvider, connectMetaMask } = useMetaMask();
  const [showProfile, setShowProfile] = useState(false);
  return (
    <>
      <UserProfile
        show={showProfile}
        onClose={() => setShowProfile(!showProfile)}
      />
      <div className="join-user-info">
        <img
          src={profileImg}
          onClick={() => setShowProfile(!showProfile)}
          className="profile-img"
          alt=""
        />
        <img src={profileBgImg} className="bg" alt="" />
        {wallet.accounts.length < 1 && (
          <Link
            to={search}
            className="text"
            style={{ cursor: "pointer" }}
            onClick={() => connectMetaMask()}
          >
            <div
              className="sub-title"
              style={{ fontSize: "18pt", paddingTop: "6px" }}
            >
              Connect Wallet
            </div>
          </Link>
        )}

        {hasProvider && wallet.accounts.length > 0 && (
          <Link to={search} className="text">
            <div
              id="disconn"
              style={{
                fontSize: "12pt",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              JammyUser#1
              <br />
              {formatAddress(wallet.accounts[0])}
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

export default ConnectWallet2;
