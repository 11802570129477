// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Contract, providers } from "ethers";
import Bingo from "../../../artifacts/contracts/Bingo.sol/Bingo.json";

const dateTimeLobby = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ap = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");
  let mergeTime = hours + "-" + ap;
  return mergeTime;
};

export const getAllGames = createAsyncThunk("getAllGames", async (params) => {
  if (typeof ethereum !== "undefined") {
    try {
      const provider = new providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const bingoContract = new Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        Bingo.abi,
        signer
      );

      const gameCounter = Number(await bingoContract.gameCounter());
      const games = await bingoContract.games(gameCounter);
      console.log(Number(games[1]));
    } catch (error) {
      console.error(error);
    }
  }

  const gameList = [
    {
      dateFormatGame: null,
      card: "Bronze",
      gameCounter: 0,
      gameCardPrice: 0,
      maxCardsPerPlayer: 0,
      totalCardsSold: 0,
      isJoinedGame: 0,
    },
  ];

  return gameList;
});

//TODO: Code iyileştirmesi yapılmalı
export const getActiveGames = createAsyncThunk(
  "getActiveGames",
  async (params) => {
    const nowtimestamp = Math.floor(Date.now() / 1000);
    let gameCounter = Number(
      await params.contractAndAuth.contract.gameCounter()
    );
    const amIDs122 = [];
    const pmIDs122 = [];
    const amIDs35 = [];
    const pmIDs35 = [];
    const amIDs68 = [];
    const pmIDs68 = [];
    const amIDs911 = [];
    const pmIDs911 = [];

    // console.log(dateTime(new Date(nowtimestamp * 1000))); // 23:59->11 pm * 00:59->12 am * 01:59->1 am * 11:59->11 am * 12:59->12 pm * 13:59->1 pm

    while (gameCounter > 0) {
      const games = await params.contractAndAuth.contract.games(gameCounter);
      const gametimestamp = Number(games[1]);
      const dateFormatGame = new Date(gametimestamp * 1000);
      const gamecancelled = games[7]; //cancelled

      if (nowtimestamp - gametimestamp >= 3600 * 3)
        // oyun saati en sondan geriye doğru bakılırken 3 saati geçerse dögüden çıkar
        break;

      if (nowtimestamp < gametimestamp && gamecancelled === false) {
        // or gameStatus === 1
        const hours = dateTimeLobby(dateFormatGame).split("-")[0];
        const ap = dateTimeLobby(dateFormatGame).split("-")[1];
        switch (Number(hours)) {
          case 12:
            if (ap === "am") amIDs122.push(gameCounter);
            if (ap === "pm") pmIDs122.push(gameCounter);
            break;

          case 1:
            if (ap === "am") amIDs122.push(gameCounter);
            if (ap === "pm") pmIDs122.push(gameCounter);
            break;

          case 2:
            if (ap === "am") amIDs122.push(gameCounter);
            if (ap === "pm") pmIDs122.push(gameCounter);
            break;

          case 3:
            if (ap === "am") amIDs35.push(gameCounter);
            if (ap === "pm") pmIDs35.push(gameCounter);
            break;

          case 4:
            if (ap === "am") amIDs35.push(gameCounter);
            if (ap === "pm") pmIDs35.push(gameCounter);
            break;

          case 5:
            if (ap === "am") amIDs35.push(gameCounter);
            if (ap === "pm") pmIDs35.push(gameCounter);
            break;

          case 6:
            if (ap === "am") amIDs68.push(gameCounter);
            if (ap === "pm") pmIDs68.push(gameCounter);
            break;

          case 7:
            if (ap === "am") amIDs68.push(gameCounter);
            if (ap === "pm") pmIDs68.push(gameCounter);
            break;

          case 8:
            if (ap === "am") amIDs68.push(gameCounter);
            if (ap === "pm") pmIDs68.push(gameCounter);
            break;

          case 9:
            if (ap === "am") amIDs911.push(gameCounter);
            if (ap === "pm") pmIDs911.push(gameCounter);
            break;

          case 10:
            if (ap === "am") amIDs911.push(gameCounter);
            if (ap === "pm") pmIDs911.push(gameCounter);
            break;

          case 11:
            if (ap === "am") amIDs911.push(gameCounter);
            if (ap === "pm") pmIDs911.push(gameCounter);
            break;
        }
      }
      gameCounter--;
    }

    const activeGamesList = [
      {
        //initialSlideIndex = 0;
        title: "12 am - 2 am",
        timeBeetween: "00-02-am",
        gameCountPerTime: amIDs122.length,
      },
      {
        //initialSlideIndex = 1;
        title: "3 am - 5 am",
        timeBeetween: "03-05-am",
        gameCountPerTime: amIDs35.length,
      },
      {
        //initialSlideIndex = 2;
        title: "6 am - 8 am",
        timeBeetween: "06-08-am",
        gameCountPerTime: amIDs68.length,
      },
      {
        //initialSlideIndex = 3;
        title: "9 am - 11 am",
        timeBeetween: "09-11-am",
        gameCountPerTime: amIDs911.length,
      },
      {
        //initialSlideIndex = 4;
        title: "12 pm - 2 pm",
        timeBeetween: "00-02-pm",
        gameCountPerTime: pmIDs122.length,
      },
      {
        //initialSlideIndex = 5;
        title: "3 pm - 5 pm",
        timeBeetween: "03-05-pm",
        gameCountPerTime: pmIDs35.length,
      },
      {
        //initialSlideIndex = 6;
        title: "6 pm - 8 pm",
        timeBeetween: "06-08-pm",
        gameCountPerTime: pmIDs68.length,
      },
      {
        //initialSlideIndex = 7;
        title: "9 pm - 11 pm",
        timeBeetween: "09-11-pm",
        gameCountPerTime: pmIDs911.length,
      },
    ];

    return activeGamesList;
  }
);

export const getAccount = createAsyncThunk("getAccount", async () => {
  const provider = new providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const bingoContract = new Contract(
    process.env.REACT_APP_CONTRACT_ADDRESS,
    Bingo.abi,
    signer
  );

  const accounts = await window.ethereum.request({
    method: "eth_accounts",
  });

  const admins = await bingoContract.admins(accounts[0]);
  const hosts = await bingoContract.hosts(accounts[0]);

  if (accounts[0] && admins) {
    return {
      account: accounts[0],
      role: "admin",
    };
  } else if (accounts[0] && hosts) {
    return {
      account: accounts[0],
      role: "host",
    };
  } else if (accounts[0]) {
    return {
      account: accounts[0],
      role: "user",
    };
  }
});

export const hasRegister = createAsyncThunk(
  "hasRegister",
  async ({ wallet }) => {
    return false;
  }
);

export const register = createAsyncThunk("register", async (params) => {
  return false;
});

export const lobbyStore = createSlice({
  name: "lobbyStore",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllGames.fulfilled, (state, action) => {
      // state.allData = action.payload
    });
  },
});

export default lobbyStore.reducer;
