import Join from '../../pages/Join'
import Game from '../../pages/Game'
import PrivateRoute from '../../@core/components/routes/PrivateRoute'

// ** Merge Routes
const Routes = [
  {
    path: '/join',
    element: <Join />,
    meta: {
      action: 'read',
      resource: 'ACL'
    }
  },
  {
    path: '/game',
    element: <Game />,
    meta: {
      action: 'read',
      resource: 'ACL'
    }
  },
]

const getRoutes = () => {
  const AllRoutes = []

  let RouteTag = PrivateRoute

 if (Routes) {
    Routes.forEach(route => {
      route.element = (
        <RouteTag route={route}>{route.element}</RouteTag>
      )

      // Push route to LayoutRoutes
      AllRoutes.push(route)
    });
  }

  return AllRoutes
}

export default getRoutes
