import React, { useEffect } from "react";

const TopInfo = ({ game }) => {
  // console.count("loaded TopInfo")
  // useEffect(() => {}, []);

  return (
    <>
      <div className="top-info">
        <div className="item">BET: {game.length > 0 ? game[2] : "0"} MATIC</div>
        <div className="item">POT: {game.length > 0 ? game[2] * game[4] : "0"} MATIC</div>
        <div className="item">
          JAMMY: {game.length > 0 ? (game[2] * game[4] * 0.4).toFixed(1) : "0"} MATIC
        </div>
      </div>
    </>
  );
};

export default React.memo(TopInfo);