import React, { useState } from "react";
import "./UserProfile.css";
import CloseButton from "../CloseButton/CloseButton";
import { Link, useLocation } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
//images
import btnBgImg from "../../assets/img/btn-bg.png"
import btnBgActiveImg from "../../assets/img/btn-bg-active.png"
import profilePopupImg from "../../assets/img/ProfilePopup.png"
import profileImg from "../../assets/img/profil-image.png"
import prevImg from "../../assets/img/prev2.png"
import nextImg from "../../assets/img/next2.png"
import twitter2Img from "../../assets/img/twitter-icon-2.svg"
import joinBtn2Img from "../../assets/img/join-btn-2.png"
import visitBtnImg from "../../assets/img/visit-btn.svg"
import inviteImg from "../../assets/img/invite.png"
import tooltipBgImg from "../../assets/img/tooltip-click-bg.png"
import staticsImg from "../../assets/img/statics-shape.png"
import totalJams1Img from "../../assets/img/total-jams.png"
import totalJams2Img from "../../assets/img/total-jams2.png"
import cardImg from "../../assets/img/card-icon.png"
import maticWonImg from "../../assets/img/matic-won.png"
import winnigImg from "../../assets/img/winnig.png"
import completedImg from "../../assets/img/completed-icon.png"
import leaderboardImg from "../../assets/img/leaderboard-icon.png"
import gameShapeImg from "../../assets/img/game-shape.png"
import createdImg from "../../assets/img/created.png"
import potImg from "../../assets/img/pot.png"
import peopleImg from "../../assets/img/people.png"
import created2Img from "../../assets/img/created2.png"
import starImg from "../../assets/img/star-icon.svg"
import rankRight7Img from "../../assets/img/ranks-right-icon-7.png"
import ranks1Img from "../../assets/img/ranks-icon-1.png"
import ranks2Img from "../../assets/img/ranks-icon-2.png"
import ranks3Img from "../../assets/img/ranks-icon-3.png"
import ranks4Img from "../../assets/img/ranks-icon-4.png"
import ranks5Img from "../../assets/img/ranks-icon-5.png"
import ranks6Img from "../../assets/img/ranks-icon-6.png"
import ranks7Img from "../../assets/img/ranks-icon-7.png"
import ranks8Img from "../../assets/img/ranks-icon-8.png"

const UserProfile = (props) => {
  const { search } = useLocation();
  const [activeLink, setActiveLink] = useState(0);
  const topLinks = ["PROFILE", "STATISTICS", "GAME HASH", "RANKS"];
  return (
    <>
      {props.show ? (
        <div className="popup-profile popup">
          <CloseButton onClose={props.onClose} to={search} />
          <div className="in">
            <div className="top-links">
              {topLinks.map((link, index) => {
                return (
                  <Link
                    to={search}
                    key={index}
                    onClick={() => setActiveLink(index)}
                    className={activeLink === index ? "active" : ""}
                  >
                    <img src={btnBgImg} alt="" />
                    <img
                      className="activeBg"
                      src={btnBgActiveImg}
                      alt=""
                    />
                    <span>{link}</span>
                  </Link>
                );
              })}
            </div>
            <div
              className="box-inner"
              style={
                activeLink === 0 ? { display: "block" } : { display: "none" }
              }
            >
              <img src={profilePopupImg} className="w-100" alt="" />
              <div className="profil-image">
                <button className="select">SELECT</button>
                <img src={profileImg} alt="" />
                <div className="profile-change-buttons">
                  <Link to={search} className="prev-btn">
                    <img src={prevImg} alt="" />
                  </Link>
                  <Link to={search} className="next-btn">
                    <img src={nextImg} alt="" />
                  </Link>
                </div>
              </div>
              <div className="text-area">
                <div className="item-row">
                  <div className="col-name">USERNAME:</div>
                  <div className="col-right">JammyUser00</div>
                </div>
                <div className="item-row">
                  <div className="col-name">WALLET ID:</div>
                  <div className="col-right">
                    0xb55360B41.............7Fa85A3b3B6
                  </div>
                </div>
                <div className="item-row">
                  <div className="col-name">MEMBERS SINCE:</div>
                  <div className="col-right">JANUARY 01,2023</div>
                </div>
                <div className="item-row">
                  <div className="col-name">HOLDINGS:</div>
                  <div className="col-right">1200 MATIC</div>
                </div>
                <div className="item-row">
                  <div className="col-name">RANK:</div>
                  <div className="col-right">NEWBY</div>
                </div>
                <div className="item-row">
                  <div className="col-name">REF CODE:</div>
                  <div className="col-right">A8SUIJM398FD</div>
                </div>
              </div>
              <div className="bottom-links">
                <Link to={search}>
                  <img src={twitter2Img} alt="" />{" "}
                  <span>FOLLOW US</span>
                </Link>
                <Link to={search}>
                  <img src={joinBtn2Img} alt="" /> <span>JOIN US</span>
                </Link>
                <Link to={search}>
                  <img src={visitBtnImg} alt="" /> <span>VISIT US</span>
                </Link>
                <Link to={search}>
                  <img src={inviteImg} width="60" alt="" />
                  <span>INVITE FRIENDS</span>
                  <small className="tooltip-content">
                    <img src={tooltipBgImg} alt="" />
                    <p>ref code copied to clipboard</p>
                  </small>
                </Link>
              </div>
            </div>
            <div
              className="box-inner"
              style={
                activeLink === 1 ? { display: "block" } : { display: "none" }
              }
            >
              <img src={profilePopupImg} className="w-100" alt="" />
              <div className="container-fluid statics">
                <div className="statics-shape">
                  <img src={staticsImg} alt="" />
                </div>
                <div className="row justify-content-center  d-flex">
                  <div className="col-lg-3 item">
                    <img src={totalJams1Img} alt="" />
                    <span>TOTAL JAMS</span>
                    <small>2400</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={totalJams2Img} alt="" />
                    <span>TOTAL JAMMYS</span>
                    <small>2400</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={cardImg} alt="" />
                    <span>CARDS PLAYED</span>
                    <small>2400</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={maticWonImg} alt="" />
                    <span>MATIC WON</span>
                    <small>2400</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={winnigImg} alt="" />
                    <span>WINNIG PERCENTAGE</span>
                    <small>2400</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={completedImg} alt="" />
                    <span>COMPLETED QUESTS</span>
                    <small>2400</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={leaderboardImg} alt="" />
                    <span>TOP OF THE LEADERBOARD</span>
                    <small>2400</small>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="box-inner"
              style={
                activeLink === 2 ? { display: "block" } : { display: "none" }
              }
            >
              <img src={profilePopupImg} className="w-100" alt="" />
              <div className="container-fluid game-hash">
                <div className="game-shape">
                  <img src={gameShapeImg} alt="" />
                </div>
                <div className="items-container">
                  <OverlayScrollbarsComponent
                    className="items-area scrollbarCustom content"
                    options={{ scrollbars: { theme: "os-theme-light" } }}
                  >
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                    <div className="item">GAME #02223562</div>
                  </OverlayScrollbarsComponent>
                  <div className="game-info d-flex flex-column">
                    <h3>GAME #555555555</h3>
                    <div className="game-hash-info-area">
                      <div className="left-area">
                        <div className="item-row">
                          <div className="icon">
                            <img src={createdImg} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>CREATED</h4>
                            <h5>08.03.2023 00:44</h5>
                          </div>
                        </div>
                        <div className="item-row">
                          <div className="icon">
                            <img src={potImg} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>POT</h4>
                            <h5>6000 MATIC</h5>
                          </div>
                        </div>
                        <div className="item-row">
                          <div className="icon">
                            <img src={peopleImg} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>PEOPLE</h4>
                            <h5>175</h5>
                          </div>
                        </div>
                        <div className="item-row">
                          <div className="icon">
                            <img src={created2Img} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>CREATED</h4>
                            <h5>3000 BRONZ</h5>
                          </div>
                        </div>
                      </div>
                      <div className="right-area">
                        <div className="icon">
                          <img src={starImg} alt="" />
                        </div>
                        <div className="text-area-right">
                          <h4>WINNERS</h4>
                          <p>WINNERJAMMY</p>
                          <p>WINNERJAMMY</p>
                          <p>WINNERJAMMY</p>
                          <p>WINNERJAMMY</p>
                          <p>WINNERJAMMY</p>
                          <p>WINNERJAMMY</p>
                        </div>
                      </div>
                    </div>
                    <div className="hash-id">
                      <span>HASH:</span>{" "}
                      <small>fn4n4v32dsc8v7aq8gb9yt9d9as9ds95nl43tx</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="box-inner"
              style={
                activeLink === 3 ? { display: "block" } : { display: "none" }
              }
            >
              <img src={profilePopupImg} className="w-100" alt="" />
              <div className="container-fluid ranks">
                <div className="ranks-shape">
                  <img src={rankRight7Img} alt="" />
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="text-center mb-3 mt-3">
                      TO BE A TRUE JAMMY MASTER!
                    </h3>
                  </div>
                </div>
                <div className="row justify-content-center  d-flex">
                  <div className="col-lg-3 item">
                    <img src={ranks1Img} alt="" />
                    <span>NEWBY</span>
                    <small>0-2000</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={ranks2Img} alt="" />
                    <span>JUNIOR</span>
                    <small>2001-5000</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={ranks3Img} alt="" />
                    <span>PRO</span>
                    <small>5001-10000</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={ranks4Img} alt="" />
                    <span>ELITE</span>
                    <small>10001-15000</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={ranks5Img} alt="" />
                    <span>SENIOR</span>
                    <small>15001-20000</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={ranks6Img} alt="" />
                    <span>MASTER</span>
                    <small>20001-30000</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={ranks7Img} alt="" />
                    <span>LEGENDARY</span>
                    <small>30001-40000</small>
                  </div>
                  <div className="col-lg-3 item">
                    <img src={ranks8Img} alt="" />
                    <span>ACE</span>
                    <small>40001+</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserProfile;
