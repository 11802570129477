import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkGamePrizes } from "../../../pages/Game/store";
import { checkGameStatus } from "../../../pages/store";
//images
import RefreshImg from "../../../assets/img/refresh.svg";
import ReplaceImg from "../../../assets/img/replace.svg";

const BottomButtons = ({
  game,
  gameStatus,
  wallet,
  contractAndAuth,
  drawnNumbers,
  setCardChangePopup,
  prizesClaimed,
}) => {
  console.log("#-> BottomButtons rendered");
  const { search } = useLocation();
  const dispatch = useDispatch();

  const gameId = useSelector((state) => state.gameStore.gameId);
  const cardCount = useSelector((state) => state.gameStore.cardCount);

  const [winners, setWinners] = useState([]);
  const [buttons, setButtons] = useState([]); //jam1: gamePrizes[4].won //jam2: gamePrizes[3].won //jam3: gamePrizes[2].won //jam4: gamePrizes[1].won //jammy: gamePrizes[0].won

  const winPrize = async (prizeIndex) => {
    if (typeof window.ethereum !== "undefined") {
      if (gameStatus === 3) {
        const gamePrize = await contractAndAuth.contract.gamePrizes(
          game[0],
          prizeIndex
        );

        if (gamePrize[1] === false) {
          for (let i = 0; i < cardCount; i++) {
            try {
              const tx = await contractAndAuth.contract.winPrize(
                game[0],
                prizeIndex,
                i
              ); //uint256 gameId, uint256 prizeIndex, uint256 playerCardIndex
              const receipt = await tx.wait();
              console.log("receipt-winPrize:", receipt);
              console.log("CardWin", i);
            } catch (error) {
              console.log("CardDoesNotWin", i, error.reason);
              continue;
            }
          }
        }
      } else {
        console.log(`wrongGameStatus (current: ${gameStatus})`);
      }
    }
  };

  useEffect(() => {
    if (contractAndAuth.contract) {
      dispatch(checkGamePrizes({ gameId, contractAndAuth })).then((result) => {
        if (result.payload) {
          console.log("checkGamePrizes:", result.payload);
          setButtons({
            jam1: result.payload[0].jam1,
            jam2: result.payload[1].jam2,
            jam3: result.payload[2].jam3,
            jam4: result.payload[3].jam4,
            jammy: result.payload[4].jammy,
          });

          // jam butonlarının yeşillenmesi için
          setWinners({
            jam1:
              result.payload[0].winners &&
              result.payload[0].winners.includes(
                wallet.accounts[0].toLowerCase()
              )
                ? true
                : false,
            jam2:
              result.payload[1].winners &&
              result.payload[1].winners.includes(
                wallet.accounts[0].toLowerCase()
              )
                ? true
                : false,
            jam3:
              result.payload[2].winners &&
              result.payload[2].winners.includes(
                wallet.accounts[0].toLowerCase()
              )
                ? true
                : false,
            jam4:
              result.payload[3].winners &&
              result.payload[3].winners.includes(
                wallet.accounts[0].toLowerCase()
              )
                ? true
                : false,
            jammy:
              result.payload[4].winners &&
              result.payload[4].winners.includes(
                wallet.accounts[0].toLowerCase()
              )
                ? true
                : false,
          });
        }
      });
    }
  }, [contractAndAuth, drawnNumbers, prizesClaimed]);

  return (
    <>
      <div className="btns-bottom">
        <Link
          to={search}
          className={!buttons.jam1 ? "btn-sub" : "btn-sub done"}
          onClick={() => winPrize(4)}
          style={winners.jam1 ? { background: "green" } : null}
        >
          1st Jam
        </Link>
        <Link
          to={search}
          className={buttons.jam1 && !buttons.jam2 ? "btn-sub" : "btn-sub done"}
          onClick={() => winPrize(3)}
          style={winners.jam2 ? { background: "green" } : null}
        >
          2nd Jam
        </Link>
        <Link
          to={search}
          className={buttons.jam2 && !buttons.jam3 ? "btn-sub" : "btn-sub done"}
          onClick={() => winPrize(2)}
          style={winners.jam3 ? { background: "green" } : null}
        >
          3rd Jam
        </Link>
        <Link
          to={search}
          className={buttons.jam3 && !buttons.jam4 ? "btn-sub" : "btn-sub done"}
          onClick={() => winPrize(1)}
          style={winners.jam4 ? { background: "green" } : null}
        >
          4th Jam
        </Link>
        <Link
          to={search}
          className={
            buttons.jam4 && !buttons.jammy ? "btn-sub" : "btn-sub done"
          }
          onClick={() => winPrize(0)}
          style={winners.jammy ? { background: "green" } : null}
        >
          JAMMY
        </Link>
        <Link
          to={search}
          onClick={() => {
            dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
              (result) => {
                if (result.payload) {
                  console.log("gameStatus:", result.payload);
                }
              }
            );
          }}
        >
          <img src={RefreshImg} alt="" />
        </Link>
        <Link
          to={search}
          onClick={() => {
            dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
              (result) => {
                if (result.payload === 1) {
                  setCardChangePopup(true);
                } else {
                  console.log(`wrongGameStatus (current: ${gameStatus})`);
                }
              }
            );
          }}
        >
          <img src={ReplaceImg} alt="" />
        </Link>
      </div>
    </>
  );
};

export default React.memo(BottomButtons);
