// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.join-user-info {
    position: absolute;
    right: 15px;
    top: -20px;
    }
    
    .join-user-info .profile-img {
    position: absolute;
    left: 11.8%;
    top: 23%;
    width: 12%;
    border-radius: 40px;
    cursor: pointer;
    z-index: 3;
    }
    
    .join-user-info .bg {
    width: 100%;
    display: block;
    }
    
    .join-user-info .text {
    position: absolute;
    left: 10%;
    top: 36%;
    text-align: center;
    width: 100%;
    }
    
    .join-user-info .text .sub-title {
    text-shadow: 0px 0px 55.4936px #00DEFF, 0px 0px 9.24894px #00DEFF, 0px 0px 2.64255px #00DEFF, 0px 0px 1.32128px #00DEFF;
    font-size: 18px;
    }
    
    .join-user-info .text .wallet-id {
    font-size: 13px;
    text-shadow: 0px 0px 55.4936px #00DEFF, 0px 0px 9.24894px #00DEFF, 0px 0px 2.64255px #00DEFF, 0px 0px 1.32128px #00DEFF;
    }
    `, "",{"version":3,"sources":["webpack://./src/components/ConnectWallet2/ConnectWallet2.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV;;IAEA;IACA,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,UAAU;IACV,mBAAmB;IACnB,eAAe;IACf,UAAU;IACV;;IAEA;IACA,WAAW;IACX,cAAc;IACd;;IAEA;IACA,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,kBAAkB;IAClB,WAAW;IACX;;IAEA;IACA,uHAAuH;IACvH,eAAe;IACf;;IAEA;IACA,eAAe;IACf,uHAAuH;IACvH","sourcesContent":[".join-user-info {\n    position: absolute;\n    right: 15px;\n    top: -20px;\n    }\n    \n    .join-user-info .profile-img {\n    position: absolute;\n    left: 11.8%;\n    top: 23%;\n    width: 12%;\n    border-radius: 40px;\n    cursor: pointer;\n    z-index: 3;\n    }\n    \n    .join-user-info .bg {\n    width: 100%;\n    display: block;\n    }\n    \n    .join-user-info .text {\n    position: absolute;\n    left: 10%;\n    top: 36%;\n    text-align: center;\n    width: 100%;\n    }\n    \n    .join-user-info .text .sub-title {\n    text-shadow: 0px 0px 55.4936px #00DEFF, 0px 0px 9.24894px #00DEFF, 0px 0px 2.64255px #00DEFF, 0px 0px 1.32128px #00DEFF;\n    font-size: 18px;\n    }\n    \n    .join-user-info .text .wallet-id {\n    font-size: 13px;\n    text-shadow: 0px 0px 55.4936px #00DEFF, 0px 0px 9.24894px #00DEFF, 0px 0px 2.64255px #00DEFF, 0px 0px 1.32128px #00DEFF;\n    }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
