import React, { useEffect, useRef, useState } from "react";
import "./DailyWheel.css";
import { Link } from "react-router-dom";
import { Contract, ethers } from "ethers";
import Roulette from "../../artifacts/contracts/Roulette.sol/Roulette.json";
import VRF from "../../artifacts/contracts/Mocks/MockCoordinator.sol/MockCoordinator.json";
import { Buffer } from "buffer";
import Token from "../../artifacts/contracts/Token.json";
import axios from "axios";
import Web3 from "web3";
//components
import CloseButton from "../CloseButton/CloseButton";
import Loading from "../Loading/Loading";
//images
import BgWheelImg from "../../assets/img/wheel/bgWheel.png";
import BackWheelImg from "../../assets/img/wheel/backWheel.png";
import WheelImg from "../../assets/img/wheel/wheel.png";
import ArrowImg from "../../assets/img/wheel/Arrow.png";
import SpinButtonImg from "../../assets/img/wheel/spinButton.png";
import AdButtonImg from "../../assets/img/wheel/adButton.png";
import ClaimButtonImg from "../../assets/img/wheel/claim.png";
import Prize0 from "../../assets/img/wheel/prize0.png";
import Prize1 from "../../assets/img/wheel/prize1.png";
import Prize2 from "../../assets/img/wheel/prize2.png";
import Prize3 from "../../assets/img/wheel/prize3.png";
import Prize4 from "../../assets/img/wheel/prize4.png";
import Prize5 from "../../assets/img/wheel/prize5.png";
import Prize6 from "../../assets/img/wheel/prize6.png";
import Prize7 from "../../assets/img/wheel/prize7.png";
import Warning from "../Warning/Warning";

const prizeImgList = [
  Prize0,
  Prize1,
  Prize2,
  Prize3,
  Prize4,
  Prize5,
  Prize6,
  Prize7,
];

const DailyWheel = (props) => {
  const startSpinningTime = 8000;
  const totalSpinningTime = startSpinningTime + 2000;

  //çark ödül fotoya göre saat yönünün tersine yazılmalı
  const prizes = [
    "Pass", //0
    "Blue",
    "Green",
    "Yellow",
    "Red",
    "Pass",
    "Green",
    "Red",
  ];

  const [rotateWheelStyle, setRotateWheelStyle] = useState();
  const [rotateArrowStyle, setRotateArrowStyle] = useState();
  const [rotateBackRadialStyle, setRotateBackRadialStyle] = useState();
  const [wheelAreaStyle, setWheelAreaStyle] = useState();
  const [prizeAreaStyle, setPrizeAreaStyle] = useState({
    display: "none",
  });

  const arrow = useRef();
  const backRadial = useRef();
  const wheelArea = useRef();
  const prizeArea = useRef();

  const [prizeNumber, setPrizeNumber] = useState();
  const [isSpin, setisSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState(null);
  const [vrf, setVrf] = useState(null);
  const [isOnBehalfOf, setisOnBehalfOf] = useState([]);
  const [fullfilled, setFulfilled] = useState(null);
  const [rand, setRand] = useState("0");
  const [warning, setWarning] = useState("");
  const [warnstatus, setWarnstatus] = useState(false);
  const [addwarning, setAddwarning] = useState("");
  const [addwarnstatus, setAddwarnstatus] = useState("");
  const [addwarnopen, setAddwaropen] = useState(false);
  const [ads, setAds] = useState(false);
  const [addtime, setAddtime] = useState(30);
  const [banner, setBanner] = useState([]);

  //çarkın dönmeye başlaması
  const onStart = () => {
    if (isSpin) {
      var rotateAngle = prizeNumber * (360 / prizes.length) + 360 * 10;
      setRotateWheelStyle({
        transform: `translate(-50%, -50%) rotate(${rotateAngle}deg)`,
        transitionDuration: `${startSpinningTime}ms`,
      });
      setRotateBackRadialStyle({
        transform: `translate(-50%, -50%) rotate(${rotateAngle}deg)`,
        transitionDuration: `${totalSpinningTime}ms`,
      });
      setRotateArrowStyle({
        animation: `${startSpinningTime}ms arrowAnimation`,
      });
      setisSpin(false);
      setTimeout(() => {
        setRotateWheelStyle({
          transform: `translate(-50%, -50%) rotate(${
            prizeNumber * (360 / prizes.length)
          }deg)`,
        });
        setRotateBackRadialStyle({
          transform: `translate(-50%, -50%) rotate(0deg)`,
        });
        setRotateArrowStyle({
          transform: `translate(-50%, -50%) rotate(0deg)`,
        });
        onFinished();
      }, totalSpinningTime);
    }
  };

  //toplam spin zamanından sonra çalışacak bitiş eventi
  const onFinished = () => {
    console.log(prizes[prizeNumber]);
    setRotateBackRadialStyle({
      animation: `30000ms linear infinite radialAnimation`,
    });
    setWheelAreaStyle({
      transitionDuration: "1s",
      transform: "scale(0)",
      opacity: "0",
    });
    setTimeout(() => {
      setPrizeAreaStyle({
        display: "block",
      });
    }, 1000);
  };
  async function sendErcToken() {
    const Web3js = new Web3(
      new Web3.providers.HttpProvider(
        "" +
          "https://dark-greatest-pond.matic-testnet.discover.quiknode.pro/03535388d4be50c36965ddcce5fc0e5dfed9d381/"
      )
    );
    //quicknode adres güncellenecek
    //private key eklenecek
    let tokenAddress = "";

    let fromAddress = "0x0478d26dBf11089EfCBa13d119f51cDc5bB8a817";
    let contract = new Web3js.eth.Contract(Token.abi, tokenAddress, {
      from: fromAddress,
    });
    let privateKey = Buffer.from(
      "22db1c94167e0b614128fce5d11219d14a6cef046492f68701078aa2339fb718",
      "hex"
    );
    let amount = Number(props.rand) - 100;
    let toAddress = "0xed91025D2aEf4Ae970e078E1955B56165323cad8";

    let data = contract.methods.transfer(toAddress, amount).encodeABI();
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        `https://dark-greatest-pond.matic-testnet.discover.quiknode.pro/03535388d4be50c36965ddcce5fc0e5dfed9d381/`
      )
    );
    // Creating a signing account from a private key
    const signer = web3.eth.accounts.privateKeyToAccount(privateKey);
    web3.eth.accounts.wallet.add(signer);
    // Creating the transaction object
    const tx = {
      from: signer.address,
      to: toAddress,
      chainId: 80001,
      value: web3.utils.toWei(amount, "ether"),
    };
    // Assigning the right amount of gas
    tx.gas = await web3.utils.toHex(400000);

    // Sending the transaction to the network
    const receipt = await web3.eth
      .sendTransaction(tx)
      .once("transactionHash", (txhash) => {
        console.log(`Mining transaction ...`);
        console.log(`Transaction hash: ${txhash}`);
      });
    // The transaction is now on chain!
    console.log(`Mined in block ${receipt.blockNumber}`);
  }
  function sendbonus() {
    // let amount = (Number(props.rand)-100); // prizeNumber da zaten tutuluyor.

    let data = {
      user: props.currentAccount,
      prize: prizes[prizeNumber], //liste de tanımlı prize ismi
    };
    axios
      .post("http://localhost:5000/api/general/claim", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log("claim response.data");
        console.log(response.data);
      })
      .catch((error) => {
        // console.log(error.data)
      });

    setPrizeAreaStyle({ display: "none" });
    setWheelAreaStyle();
    setRotateBackRadialStyle();
    setisSpin(false);
    setWarning("Ödülünüz başarıyla gönderildi");
  }
  const onClaim = async () => {
    console.log("claim ediliyor");
    // window.ethereum
    if (window.ethereum) {
      const exampleMessage = "Do you want to claim your daily bonus prize? ";
      try {
        const from = "0x0478d26dBf11089EfCBa13d119f51cDc5bB8a817";
        // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
        // This uses a Node.js-style buffer shim in the browser.
        const msg = `0x${Buffer.from(exampleMessage, "utf8").toString("hex")}`;
        const sign = await window.ethereum.request({
          method: "personal_sign",
          params: [exampleMessage, from],
        });
        console.log("signddd");
        console.log(sign);
        if (sign.length > 0) {
          console.log("ödülü gönder");
          sendbonus();
          sendErcToken();
        } else {
          console.log("hata ödülü gönderme");
        }
      } catch (err) {
        console.error(err);
        console.log("catch hata ödülü gönderme");
      }
    }
    // onayla();
  };

  const checkNetwork = async () => {
    try {
      console.log("Checking network...");
      if (
        props.currentAccount &&
        window.ethereum.networkVersion !== process.env.REACT_APP_NETWORKVERSION
      ) {
        console.log(
          `Changing network to ${process.env.REACT_APP_NETWORKNAME}...`
        );
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: process.env.REACT_APP_NETWORKID }],
        });
        console.log(`Network changed to ${process.env.REACT_APP_NETWORKNAME}.`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const contractislemleri = async () => {
    let privateKey = Buffer.from(
      "22db1c94167e0b614128fce5d11219d14a6cef046492f68701078aa2339fb718",
      "hex"
    );
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78"
      );
      const signer = new ethers.Wallet(privateKey, provider);
      let rouletteContract = new Contract(
        "0x7fd9f48664dafa9d03320958f7ded119cd179634",
        Roulette.abi,
        signer
      );
      let vrfContract = new Contract(
        process.env.REACT_APP_VRF_CONTRACT_ADDRESS,
        VRF.abi,
        signer
      );
      if (!contract) setContract(rouletteContract);
      if (!vrf) setVrf(vrfContract);

      //TODO : IsAccountOperator inactive!
      // if(contract && props.currentAccount){
      //   (async () => {
      //     const operator = await contract.operators(props.currentAccount);
      //     setIsAccountOperator(operator);
      //   })();

      // event RequestSent(uint256 indexed requestId, address indexed onBehalfOf) dinliyor
      contract.once("RequestSent", async (requestId, onBehalfOf) => {
        console.log("RequestSent event was emmited");
        console.log("requestId:", requestId.toString());
        console.log("onBehalfOf:", onBehalfOf.toString());

        const reqResult = await contract.randomRequests(requestId);
        console.log("reqResult:", Number(reqResult));
        // 1337 > request sonucu random sayı beklediğini gösterir (daha random sayı istenmediği anlamına gelir).
        // her requestid rasgele sayı gelene kadar default olarak önce 1337 ayarlanır.
        setisOnBehalfOf([
          onBehalfOf.toString(),
          Number(requestId),
          Number(reqResult),
        ]);

        if (Number(reqResult) === 1337) {
          const tx = await vrf.mockFulfillment(requestId, [653297]); // vfr ten rasgele sayı isteği sonucu 999, 155, 350, 653297 .. gibi sayılar geldiği simüle ediliyor.
          console.log("Gelecek olan rasgele sayı:", (653297 % 8) + 100); // vrf ten gelen 653297 bu sayı kontratta 101, 102, .. gibi sayılara çevriliyor.
          const receipt = await tx.wait();
          console.log("mockFulfillment:", receipt);
        }
      });

      // event RequestFulfilled(uint256 indexed requestId, uint256 result) dinliyor
      contract.once("RequestFulfilled", async (requestId, result) => {
        console.log("RequestFulfilled event was emmited");
        console.log("requestId:", requestId.toString());
        console.log("randomwords:", result.toString()); // sonuç olarak gelen random sayı budur.
        setFulfilled(result.toString());
        setisOnBehalfOf([isOnBehalfOf[0], Number(requestId), Number(result)]);
        let randomwords = await contract.randomRequests(requestId).then((e) => {
          console.log("eeeeeeeee");
          console.log(e);
        });
        setRand(randomwords);
        console.log(
          "randomwords:",
          Number(await contract.randomRequests(requestId))
        ); // randomRequests(requestId) 1337 den gelen random sayıya güncellenir.
        // TODO : Yapıya uygunluğu kontrol edilecek !
        //rangele gelen sayı setFullfilled ile mi setRand ile mi belirlendi?
        setPrizeNumber(Number(fullfilled) - 100);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  };
  function countDownFrom(seconds) {
    if (seconds < 0) {
      console.log("Cannot count down from a negative number.");
      return;
    }

    let timer = setInterval(() => {
      console.log(seconds);
      seconds--;
      setAddtime(seconds);
      if (seconds < 0) {
        clearInterval(timer);
        console.log("Close Ads");
        setAddtime(0);

        sendAdddata();
      }
    }, 1000);
  }
  const sendAdddata = () => {
    let data = {
      user: props.account,
    };

    axios
      .post(
        "http://localhost:5000/api/general/userads",
        data,

        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        console.log("banner response.data");
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.data);
      });
  };
  const getbanner = () => {
    console.log("banner çekiliyor");
    let data = {
      user: props.currentAccount,
    };
    axios
      .post(
        "http://localhost:5000/api/general/ads",
        data,

        { headers: { "Content-Type": "application/json" } }
      )

      .then((response) => {
        console.log("banner response.data");
        console.log(response.data);

        if (response.data.bstatus === "error") {
          setAddwarning(response.data.message);
          setBanner("");
          setAddwarnstatus(response.data.bstatus);
        } else {
          setBanner(response.data);
          setAddwarning("");
          setAddwarnstatus(response.data.bstatus);
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  };
  //Reklam butonu
  const onAdClick = () => {
    getbanner(false);
    if (addwarnstatus === "error") {
      // console.log("addwarning error");     console.log(addwarning);
      setAds(false);
      setAddwaropen(true);
    } else {
      setAddwaropen(false);
      console.log("addwarning success");
      console.log(addwarning);
      setAds(true);
      setisSpin(true);
      countDownFrom(30);
    }
  };
  function addstatus() {
    setAds(false);
    setAddwaropen(false);
  }
  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      // checkNetwork();
      //TODO: Hatalar dönüyor geçici olarak kapatıldı.
      // contractislemleri();
    }
  }, []);

  return (
    <>
      {props.show ? (
        <>
          {loading ? (
            <Loading fullscreen={true} />
          ) : (
            <div className="popup-daily-wheel popup">
              {warning.length > 0 ? (
                <Warning
                  show={warnstatus}
                  onClose={() => setWarnstatus(!warnstatus)}
                  title="Warning"
                  text={warning}
                />
              ) : null}

              <CloseButton onClose={props.onClose} />
              <div className="in">
                <img
                  src={BgWheelImg}
                  ref={backRadial}
                  style={rotateBackRadialStyle}
                  className="w-100 wheel"
                  alt=""
                />
                <div ref={wheelArea} style={wheelAreaStyle}>
                  <img src={BackWheelImg} className="w-100" alt="" />
                  <img
                    src={WheelImg}
                    style={rotateWheelStyle}
                    className="w-100 wheel"
                    alt=""
                  />
                  <img
                    src={ArrowImg}
                    style={rotateArrowStyle}
                    className="arrow"
                    alt=""
                    ref={arrow}
                  />
                  <div className="button-area">
                    {isSpin && (
                      <Link
                        onClick={onStart}
                        className="spin-button"
                        //TODO : Button disabled! Daily enable control from network
                        style={
                          isSpin
                            ? { pointerEvents: "auto" }
                            : { pointerEvents: "none" }
                        }
                      >
                        <img src={SpinButtonImg} className="w-100" alt="" />
                      </Link>
                    )}
                    <Link onClick={onAdClick} className="ad-button">
                      <img src={AdButtonImg} className="w-100" alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="popup-daily-win"
                  ref={prizeArea}
                  style={prizeAreaStyle}
                >
                  <div className="area">
                    <img
                      src={prizeImgList[prizeNumber]}
                      className="prizeImg"
                      alt=""
                    ></img>
                    <span>{prizes[prizeNumber]}</span>

                    {prizes[prizeNumber] !== "Pass" && (
                      <Link onClick={onClaim} className="claim-button">
                        <img src={ClaimButtonImg} className="w-100" alt="" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default DailyWheel;
