import React, { useEffect } from "react";

const LeftLine = ({ headNumbers, drawnNumbers, passiveNumbers }) => {
  console.log("#-> LeftLine rendered");
  useEffect(() => {
    //console.log("useEffect-> LeftLine:", headNumbers, drawnNumbers, passiveNumbers);
  }, []);

  return (
    <>
      <div className="item-numbers-left">
        <div className="navigation-number">
          <div className="draw-numbers-area">
            {headNumbers.length > 0 &&
              headNumbers
                .slice(0, 5) // dizinin ilk 5 elemenı
                .map((number, numberindex) =>
                  number > 0 && number <= 15 ? (
                    numberindex === 0 ? (
                      <div className="item activeItem yellow" key={numberindex}>
                        {number}
                      </div>
                    ) : (
                      <div className="item yellow" key={numberindex}>
                        {number}
                      </div>
                    )
                  ) : number > 15 && number <= 30 ? (
                    numberindex === 0 ? (
                      <div className="item activeItem red" key={numberindex}>
                        {number}
                      </div>
                    ) : (
                      <div className="item red" key={numberindex}>
                        {number}
                      </div>
                    )
                  ) : number > 30 && number <= 45 ? (
                    numberindex === 0 ? (
                      <div className="item activeItem purple" key={numberindex}>
                        {number}
                      </div>
                    ) : (
                      <div className="item purple" key={numberindex}>
                        {number}
                      </div>
                    )
                  ) : number > 45 && number <= 60 ? (
                    numberindex === 0 ? (
                      <div className="item activeItem green" key={numberindex}>
                        {number}
                      </div>
                    ) : (
                      <div className="item green" key={numberindex}>
                        {number}
                      </div>
                    )
                  ) : number > 60 && number <= 75 ? (
                    numberindex === 0 ? (
                      <div className="item activeItem blue" key={numberindex}>
                        {number}
                      </div>
                    ) : (
                      <div className="item blue" key={numberindex}>
                        {number}
                      </div>
                    )
                  ) : null
                )}
          </div>
        </div>
        <div className="numbers-container">
          <div className="list-item">
            {drawnNumbers.length > 0 &&
              drawnNumbers.map(
                (number, numberindex) =>
                  number > 0 &&
                  number <= 15 && (
                    <span className="yellow" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
            {passiveNumbers.length > 0 &&
              passiveNumbers.map(
                (number, numberindex) =>
                  number > 0 &&
                  number <= 15 && (
                    <span className="passive" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
          </div>
          <div className="list-item">
            {drawnNumbers.length > 0 &&
              drawnNumbers.map(
                (number, numberindex) =>
                  number > 15 &&
                  number <= 30 && (
                    <span className="red" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
            {passiveNumbers.length > 0 &&
              passiveNumbers.map(
                (number, numberindex) =>
                  number > 15 &&
                  number <= 30 && (
                    <span className="passive" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
          </div>
          <div className="list-item">
            {drawnNumbers.length > 0 &&
              drawnNumbers.map(
                (number, numberindex) =>
                  number > 30 &&
                  number <= 45 && (
                    <span className="purple" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
            {passiveNumbers.length > 0 &&
              passiveNumbers.map(
                (number, numberindex) =>
                  number > 30 &&
                  number <= 45 && (
                    <span className="passive" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
          </div>
          <div className="list-item">
            {drawnNumbers.length > 0 &&
              drawnNumbers.map(
                (number, numberindex) =>
                  number > 45 &&
                  number <= 60 && (
                    <span className="green" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
            {passiveNumbers.length > 0 &&
              passiveNumbers.map(
                (number, numberindex) =>
                  number > 45 &&
                  number <= 60 && (
                    <span className="passive" key={numberindex}>
                      <small>{number}</small>
                    </span>
                  )
              )}
          </div>
          <div className="list-item">
            {drawnNumbers.map(
              (number, numberindex) =>
                number > 60 &&
                number <= 75 && (
                  <span className="blue" key={numberindex}>
                    <small>{number}</small>
                  </span>
                )
            )}
            {passiveNumbers.map(
              (number, numberindex) =>
                number > 60 &&
                number <= 75 && (
                  <span className="passive" key={numberindex}>
                    <small>{number}</small>
                  </span>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(LeftLine);
