import { Link } from "react-router-dom";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import { formatAddress } from "../../utility/Utils";
import "./ConnetWallet.css";
import connectButtonImg from "../../assets/img/connect-btn.png"

const ConnectWallet = () => {
  const { wallet, hasProvider, connectMetaMask } = useMetaMask()

  return (
    <>
      {wallet.accounts.length < 1 &&
        <Link href="#" className="connect-head-btn" onClick={() => connectMetaMask()}>
          <img src={connectButtonImg} alt="" />
          <span>Connect Wallet</span>
        </Link>
      }

      {hasProvider && wallet.accounts.length > 0 &&
        <Link href="#" className="connect-head-btn">
          <img src={connectButtonImg} alt="" />
          <span
            id="disconn"
            style={{ fontSize: "12pt", textAlign: "center", cursor: "pointer" }}
          >
            JammyUser#1
            <br />
            {formatAddress(wallet.accounts[0])}
          </span>
        </Link>
      }
    </>
  )
}

export default ConnectWallet