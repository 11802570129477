import { defineAbility } from '@casl/ability'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))

export default defineAbility((can, cannot) => {
  if(!userData) {
    can('read', 'Auth');
    cannot('read', 'Auth')
    can('read', 'Host')
  }
});
