// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-area {
    position: absolute;
    z-index: 100;
    background-color: rgb(38 32 32 / 30%);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
}
.loading-area .fullscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}
.loading-area .area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.loading-area span{
    margin-top: 1%;
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,qCAAqC;IACrC,0BAA0B;IAC1B,kCAAkC;IAClC,WAAW;IACX,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;AACA;IACI,cAAc;AAClB","sourcesContent":[".loading-area {\n    position: absolute;\n    z-index: 100;\n    background-color: rgb(38 32 32 / 30%);\n    backdrop-filter: blur(3px);\n    -webkit-backdrop-filter: blur(3px);\n    width: 100%;\n    height: 100%;\n}\n.loading-area .fullscreen {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100vw;\n    height: 100vh;\n}\n.loading-area .area {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n}\n.loading-area span{\n    margin-top: 1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
