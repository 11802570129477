import React from "react";
import { Link } from "react-router-dom";
import CloseButton from "../CloseButton/CloseButton";
import "./Quests.css";
//images
import questPopupImg from "../../assets/img/Jammy-Quests-Popup.png"
import twitterImg from "../../assets/img/twitter-icon.png"
import playImg from "../../assets/img/play-icon.png"

const Quests = (props) => {
  return (
    <>
      {props.show ? (
        <div
          className="popup-quests popup"
        >
          <CloseButton onClose={props.onClose}/>
          <div className="in">
            <img src={questPopupImg} className="w-100" alt="" />
            <div className="content-area">
              <div className="item-row">
                <div className="name">
                  <span>Follow us on twitter</span>
                  <small>Reward:</small>
                </div>
                <div className="right-area">
                  <div className="icon">
                    <img src={twitterImg} alt="" />
                  </div>
                  <div className="num">1/1</div>
                  <Link className="btn-area done">DONE</Link>
                </div>
              </div>
              <div className="item-row">
                <div className="name">
                  <span>Follow us on twitter</span>
                  <small>Reward:</small>
                </div>
                <div className="right-area">
                  <div className="icon">
                    <img src={playImg} alt="" />
                  </div>
                  <div className="num">1/1</div>
                  <Link className="btn-area claim">CLAIM</Link>
                </div>
              </div>
              <div className="item-row">
                <div className="name">
                  <span>Follow us on twitter</span>
                  <small>Reward:</small>
                </div>
                <div className="right-area">
                  <div className="icon">
                    <img src={twitterImg} alt="" />
                  </div>
                  <div className="num">1/1</div>
                  <Link className="btn-area go">GO</Link>
                </div>
              </div>
              <div className="item-row">
                <div className="name">
                  <span>Follow us on twitter</span>
                  <small>Reward:</small>
                </div>
                <div className="right-area">
                  <div className="icon">
                    <img src={twitterImg} alt="" />
                  </div>
                  <div className="num">1/1</div>
                  <Link className="btn-area go">GO</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Quests;
