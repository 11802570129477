import React from "react";
import { Link } from "react-router-dom";
import "./CloseButton.css";
import CloseImg from '../../assets/img/close.svg'

const CloseButton = (props) => {
  return (
    <Link to={props.to} onClick={props.onClose} className="close-btn">
      <img src={CloseImg} className="w-100" alt="" />
    </Link>
  );
};

export default CloseButton;
