// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BigNumber, utils } from "ethers";
import { hexToArry } from "../../../components/CreateCards";

export const urlParams = createAsyncThunk("urlParams", async (params) => {
  const gameId = Number(params.searchParams.get("gameId"));
  const cardCount = Number(params.searchParams.get("cards"));
  const reqId = Number(params.searchParams.get("reqId"));
  return { gameId, cardCount, reqId };
});

export const fetchGame = createAsyncThunk(
  "fetchGame",
  async (params, { dispatch, getState }) => {
    const games = await params.contractAndAuth.contract.games(params.gameId);
    const gametimestamp = Number(games[1]);
    const gamecardprice = Number(
      (+utils.formatEther(BigNumber.from(games[3]))).toFixed(0)
    );
    const maxCardsPerPlayer = Number(games[2]);
    const totalCardsSold = Number(games[4]);
    const houseShare = Number(games[5]);
    const gameSeed = Number(games[6]);

    const game = [
      params.gameId,
      gametimestamp,
      gamecardprice,
      maxCardsPerPlayer,
      totalCardsSold,
      gameSeed,
      houseShare,
    ];

    return game;
  }
);

export const getCard = createAsyncThunk(
  "getCard",
  async ({ gameId, contractAndAuth, wallet, playerCardIndex }) => {
    let cardIndex = 0;
    let errors = [];
    let playerHexCard = null;

    try {
      playerHexCard = await contractAndAuth.contract.playerCards(
        gameId,
        wallet.accounts[0].toLowerCase(),
        playerCardIndex
      );
    } catch (error) {
      errors.push("card not found");
      return { cardIndex: null, cardNumbers: null, errors: errors };
    }

    if (Number(playerHexCard) !== 1) {
      try {
        //TODO: Kart uzunluğu apiden alınacak (200)
        for (cardIndex; cardIndex < 200; cardIndex++) {
          const cards = await contractAndAuth.contract.cards(cardIndex);
          if (cards._hex === playerHexCard._hex) {
            break;
          }
        }

        return {
          cardIndex: cardIndex,
          cardNumbers: hexToArry(playerHexCard),
          errors: null,
        };
      } catch (err) {
        errors.push("card index not found");
        return { cardIndex: null, cardNumbers: null, errors: errors };
      }
    } else {
      errors.push("card not ready");
      return { cardIndex: null, cardNumbers: null, errors: errors };
    }
  }
);

export const cardReveal = createAsyncThunk(
  "cardReveal",
  async ({ gameId, contractAndAuth, wallet, cardCount, reqId }) => {
    let tx = null;
    let receipt = null;
    let hash = null;
    let errors = [];
    const redrawReqId =
      localStorage.getItem(
        `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
      ) !== undefined
        ? localStorage.getItem(
            `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
          )
        : null;

    if (redrawReqId === null) {
      const hexCard = await contractAndAuth.contract.playerCards(
        gameId,
        wallet.accounts[0].toLowerCase(),
        0
      );

      if (Number(hexCard) === 1) {
        let randomNums = [];
        for (let i = 0; i >= 0; i++) {
          if (randomNums.length >= 4) {
            break;
          } else {
            const num = Math.floor(Math.random() * 9000) + 1000;
            if (!randomNums.includes(num)) {
              randomNums.push(num);
            }
          }
        }

        switch (cardCount) {
          case 1:
            tx = await contractAndAuth.vrf.mockFulfillment(reqId, [
              randomNums[0],
            ]);
            receipt = await tx.wait();
            hash = receipt.transactionHash;
            break;

          case 2:
            tx = await contractAndAuth.vrf.mockFulfillment(reqId, [
              randomNums[0],
              randomNums[1],
            ]);
            receipt = await tx.wait();
            hash = receipt.transactionHash;
            break;

          case 3:
            tx = await contractAndAuth.vrf.mockFulfillment(reqId, [
              randomNums[0],
              randomNums[1],
              randomNums[2],
            ]);
            receipt = await tx.wait();
            hash = receipt.transactionHash;
            break;

          case 4:
            tx = await contractAndAuth.vrf.mockFulfillment(reqId, [
              randomNums[0],
              randomNums[1],
              randomNums[2],
              randomNums[3],
            ]);
            receipt = await tx.wait();
            hash = receipt.transactionHash;
            break;
        }

        return { hash, errors };
      } else {
        errors.push("cards revealed");
        //hash ="dadaw31321312dawada";
        return { hash, errors };
      }
    } else {
      errors.push("card not ready");
      return { hash, errors };
    }
  }
);

export const cardRedraw = createAsyncThunk(
  "cardRedraw",
  async ({ gameId, contractAndAuth, wallet }) => {
    let errors = [];
    let hash = null;
    const redrawReqId =
      localStorage.getItem(
        `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
      ) !== undefined
        ? localStorage.getItem(
            `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
          )
        : null;

    if (redrawReqId !== null) {
      //TODO: locals verisini backendde tutmak daha iyi olabilir.
      try {
        const randomNum = Math.floor(Math.random() * 9000) + 1000;
        const tx = await contractAndAuth.vrf.mockFulfillment(
          redrawReqId,
          [randomNum],
          {
            gasLimit: 30000000,
          }
        );
        const receipt = await tx.wait();
        hash = receipt.transactionHash;
        return { hash, errors };
      } catch (err) {
        errors.push(err.reason);
        return { hash, errors };
      }
    } else {
      errors.push("localS not found");
      return { hash, errors };
    }
  }
);

export const checkGamePrizes = createAsyncThunk(
  "checkGamePrizes",
  async (params) => {
    let prizeObj4 = { jam1: false, winners: null };
    let prizeObj3 = { jam2: false, winners: null };
    let prizeObj2 = { jam3: false, winners: null };
    let prizeObj1 = { jam4: false, winners: null };
    let prizeObj0 = { jammy: false, winners: null };

    const jam1 = (
      await params.contractAndAuth.contract.gamePrizes(params.gameId, 4)
    )[1];
    const jam2 = (
      await params.contractAndAuth.contract.gamePrizes(params.gameId, 3)
    )[1];
    const jam3 = (
      await params.contractAndAuth.contract.gamePrizes(params.gameId, 2)
    )[1];
    const jam4 = (
      await params.contractAndAuth.contract.gamePrizes(params.gameId, 1)
    )[1];
    const jammy = (
      await params.contractAndAuth.contract.gamePrizes(params.gameId, 0)
    )[1];

    if (jam1) {
      let winners = [];
      try {
        for (let i = 0; ; i++) {
          winners.push(
            (
              await params.contractAndAuth.contract.prizeWinners(
                params.gameId,
                4,
                i
              )
            ).toLowerCase()
          );
        }
      } catch (error) {
        console.log(">>> jam1:", error.reason);
      }
      prizeObj4 = { jam1: jam1, winners: winners };
    }

    if (jam2) {
      let winners = [];
      try {
        for (let i = 0; ; i++) {
          winners.push(
            (
              await params.contractAndAuth.contract.prizeWinners(
                params.gameId,
                3,
                i
              )
            ).toLowerCase()
          );
        }
      } catch (error) {
        console.log(">>> jam2:", error.reason);
      }
      prizeObj3 = { jam2: jam2, winners: winners };
    }

    if (jam3) {
      let winners = [];
      try {
        for (let i = 0; ; i++) {
          winners.push(
            (
              await params.contractAndAuth.contract.prizeWinners(
                params.gameId,
                2,
                i
              )
            ).toLowerCase()
          );
        }
      } catch (error) {
        console.log(">>> jam3:", error.reason);
      }
      prizeObj2 = { jam3: jam3, winners: winners };
    }

    if (jam4) {
      let winners = [];
      try {
        for (let i = 0; ; i++) {
          winners.push(
            (
              await params.contractAndAuth.contract.prizeWinners(
                params.gameId,
                1,
                i
              )
            ).toLowerCase()
          );
        }
      } catch (error) {
        console.log(">>> jam4:", error.reason);
      }
      prizeObj1 = { jam4: jam4, winners: winners };
    }

    if (jammy) {
      let winners = [];
      try {
        for (let i = 0; ; i++) {
          winners.push(
            (
              await params.contractAndAuth.contract.prizeWinners(
                params.gameId,
                0,
                i
              )
            ).toLowerCase()
          );
        }
      } catch (error) {
        console.log(">>> jammy:", error.reason);
      }
      prizeObj0 = { jammy: jammy, winners: winners };
    }

    return [prizeObj4, prizeObj3, prizeObj2, prizeObj1, prizeObj0];
  }
);

export const checkNumbers = createAsyncThunk(
  "checkNumbers",
  async ({ gameId, contractAndAuth, gameStatus }) => {
    let headNumbers = [];
    let drawnNumbers = [];
    let passiveNumbers = [];
    let lastRevealedNumber = 0;
    const hostDelay = 1; //host sayı çekme ekranı için

    if (gameStatus === 3) {
      if (localStorage.getItem(`revealNumbers-${gameId}`)) {
        lastRevealedNumber = localStorage
          .getItem(`revealNumbers-${gameId}`)
          .split(",")[0]; //host sayı çekme ekranı için

        for (
          let i = 0;
          i < localStorage.getItem(`revealNumbers-${gameId}`).split(",").length;
          i++
        ) {
          if (i < 5) {
            headNumbers.push(
              localStorage.getItem(`revealNumbers-${gameId}`).split(",")[i]
            );
          } else {
            break;
          }
        }
      }

      // TODO : FE - Farklı bi döngü yapısına bakılacak
      console.log("--->store-drawnNumbers");
      for (let i = 1; i <= 75; i++) {
        const resultDrawn = await contractAndAuth.contract.drawnNumbers(
          gameId,
          i
        );
        if (resultDrawn === true) {
          drawnNumbers.push(i);
        }
      }
      console.log("--->store-drawnNumbers", drawnNumbers);

      // TODO : FE - Farklı bi döngü yapısına bakılacak
      console.log("--->store-passiveNumbers");
      for (let i = 0; i < 75 - drawnNumbers.length; i++) {
        const resultNumbers = await contractAndAuth.contract.numbers(gameId, i);
        passiveNumbers.push(resultNumbers);
      }
      console.log("--->store-passiveNumbers", passiveNumbers);
    } else {
      console.log(`checkNumbers: wrongGameStatus (${gameStatus})`);
    }

    return {
      headNumbers,
      drawnNumbers,
      passiveNumbers,
      lastRevealedNumber,
      hostDelay,
    };
  }
);

export const gameStore = createSlice({
  name: "gameStore",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(urlParams.fulfilled, (state, action) => {
      state.gameId = action.payload.gameId;
      state.cardCount = action.payload.cardCount;
      state.reqId = action.payload.reqId;
    });

    builder.addCase(fetchGame.fulfilled, (state, action) => {
      state.game = action.payload;
    });
  },
});

export default gameStore.reducer;
