import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./HostGame.css";
//images
import singleCardImg from "../../../assets/img/single-card.png"

const HostRevealNumber = ({ game, contractAndAuth, gameStatus, lastRevealedNumber, hostDelay }) => {
  const { search } = useLocation();
  const [seconds, setSeconds] = useState(0);

  const revealNumber = async (gameId) => {
    //TODO : Contract - gameStatus durumlarını kontrol et, locals leri clear yapacaksın, emit GameEnds(gameId) eventini kontrol et
    if (typeof window.ethereum !== "undefined" && gameStatus === 3) {
      const tx1 = await contractAndAuth.contract.revealNumber(gameId); // her seferinde rasgele sayı çekiyor
      const receipt1 = await tx1.wait();
      console.log("revealNumber:", receipt1);

      // const availableNumbers = await contract.availableNumbers(74);
      // console.log("availableNumbers:", availableNumbers);

      setSeconds(hostDelay);
    } else {
      console.log(`revealNumber: wrongGameStatus (${gameStatus})`);
    }
  };

  useEffect(() => {
    console.log("useEffect-> HostRevealNumber:", gameStatus, lastRevealedNumber, hostDelay);
    const countdown = setInterval(() => {
      if (seconds === 0) {
        clearInterval(countdown);
        return;
      }
      setSeconds(seconds - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [seconds]);

  return (
    <div className="single-card-item number-card-item">
      <div className="in-box">
        <span 
          className={lastRevealedNumber > 0 && lastRevealedNumber <= 15 ? 'yellow' : lastRevealedNumber > 15 && lastRevealedNumber <= 30 ? 'red' : lastRevealedNumber > 30 && lastRevealedNumber <= 45 ? 'purple' : lastRevealedNumber > 45 && lastRevealedNumber <= 60 ? 'green' : 'blue'}
          style={lastRevealedNumber !== 0 ? {display: 'flex'} : {display: 'none'}}>
          <small>{lastRevealedNumber !== 0 ? lastRevealedNumber : null}</small>
        </span>
        <div className="btns-bottom">
          <Link to={search} className={seconds <= 0 ? "btn-sub" : "btn-sub done"} onClick={() => revealNumber(game[0])}>
            {seconds <= 0 ? "Draw a number" : seconds < 10 ? `wait (${seconds} sec)` : seconds}
          </Link>
        </div>
      </div>
      <img src={singleCardImg} className="" alt="" />
    </div>
  );
};

export default React.memo(HostRevealNumber);