import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { create5x5, arrayToHex } from '../../components/CreateCards';
import ConnectWallet from "../../components/ConnectWallet";
import { useMetaMask } from "../../utility/hooks/useMetaMask";

function Panel(){
  const { wallet, contractAndAuth } = useMetaMask();
  const [txId, setTxId] = useState(null);

  // const deployerAddress = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
  const adminAddress = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266"; //0x70997970C51812dc3A010C7d01b50e0d17dc79C8
  const hostAddress = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266"; //0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC
  // const player1Address = "0x90F79bf6EB2c4f870365E785982E1f101E93b906";
  // const player2Address = "0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65";

  const mockCards = [];
  for(let i = 0; i < 128; i++){
    mockCards.push(create5x5());
  }

  const setAdmin = async (id, price) => {
    try {
      if(typeof window.ethereum !== 'undefined') {
        const tx = await contractAndAuth.contract.setAdmin(adminAddress, true );
        const receipt = await tx.wait();
        setTxId(receipt.transactionHash);
        console.log("receipt:", receipt);

        contractAndAuth.contract.on("AdminSet", async (account, state) => {
          console.log("AdminSet event was emmited");
          console.log("account:", account.toString());
          console.log("state:", state.toString());
        })
      }
    } catch (error) {
      console.error(error.reason);
    }
  };

  const setHost = async () => {
    try {
      if(typeof window.ethereum !== 'undefined') {
        const tx = await contractAndAuth.contract.setHost(hostAddress, true );
        const receipt = await tx.wait();
        setTxId(receipt.transactionHash);
        console.log("receipt:", receipt);

        contractAndAuth.contract.on("HostSet", async (account, state) => {
          console.log("HostSet event was emmited");
          console.log("account:", account.toString());
          console.log("state:", state.toString());
        })
      }
    } catch (error) {
      console.error(error.reason);
    }
  };

  const batchAddCards = async () => {
    try {
      if(typeof window.ethereum !== 'undefined') {
        const newCards = mockCards.map((c) => ethers.BigNumber.from(arrayToHex(c)));
        const tx = await contractAndAuth.contract.batchAddCards(newCards);
        const receipt = await tx.wait();
        setTxId(receipt.transactionHash);
        console.log("receipt:", receipt);

        contractAndAuth.contract.on("CardsAdded", async (amount, newCount) => {
          console.log("CardsAdded event was emmited");
          console.log("amount:", amount.toString());
          console.log("newCount:", newCount.toString());
          console.log("cards array:", mockCards);
        })
      }
    } catch (error) {
      console.error(error.reason);
    }
  };

  const batchUpdateCards = async () => {
    try {
      if(typeof window.ethereum !== 'undefined') {
        const newCards = mockCards.map((c) => ethers.BigNumber.from(arrayToHex(c)));
        const tx = await contractAndAuth.contract.batchUpdateCards([0, 1], [newCards[4], newCards[5]]);
        const receipt = await tx.wait();
        setTxId(receipt.transactionHash);
        console.log("receipt:", receipt);

        contractAndAuth.contract.on("CardsUpdated", async (amount) => {
          console.log("CardsUpdated event was emmited");
          console.log("amount:", amount.toString());
        })

        //batchAddCards();
      }
    } catch (error) {
      console.error(error.reason);
    }
  };

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      try {
        if (wallet.accounts.length > 0 && contractAndAuth.contract) {
          console.log("if:", contractAndAuth)
        } else {
          console.log("else:", contractAndAuth)
        }
      } catch (error) {
        console.error(error.reason);
      }
    }

  }, [contractAndAuth])

  return (
    <div className='AppPanel' style={{textAlign:'center'}}>
      <header style={{backgroundColor:'#444', minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', color:'#000'}}>
        <div>
          <ConnectWallet />
        </div>
        <div>
          <h4>Protocol Setups</h4>
          <div style={{border: '2px solid #fff', padding: '10px', margin: '4px', borderRadius: '12px'}}>
            <button onClick={setAdmin} style={{color:'#369', padding:'0 8px', borderRadius: '6px'}}>setAdmin (deployer)</button><br/>
            <button onClick={setHost} style={{color:'#369', padding:'0 8px', borderRadius: '6px'}}>setHost (admin)</button><br/>
            <button onClick={batchAddCards} style={{color:'#369', padding:'0 8px', borderRadius: '6px'}}>batchAddCards (admin)</button><br/>
            <button onClick={batchUpdateCards} style={{color:'#369', padding:'0 8px', borderRadius: '6px'}}>batchUpdateCards (admin)</button><br/>
          </div>
        </div>
        <p style={{fontSize:'11pt', border: '2px solid #fff', borderRadius: '12px', padding: '6px 12px'}}>TX Hash: <span style={{color:'#ddd'}}>{txId}</span></p>
      </header>
    </div>
  )
}

export default Panel;