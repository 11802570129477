import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BigNumber, utils } from "ethers";
import { useSelector } from "react-redux";
import "./HostGame.css";
//images
import singleCardImg from "../../../assets/img/single-card.png";

//TODO : Contract - Genel gamestatus durumu kontrol edilecek
const HostStartGame = ({ game, contractAndAuth, gameStatus }) => {
  const { search } = useLocation();
  const nowtimestamp = Math.floor(Date.now() / 1000);
  const gametimestamp = Number(game[1]);
  let { initialSeconds = Number(gametimestamp - nowtimestamp) } = "props";
  const [seconds, setSeconds] = useState(
    initialSeconds <= 0 ? 0 : initialSeconds
  );
  const gameId = useSelector((state) => state.gameStore.gameId);

  //TODO : Contract - host paneldeki startgame i buna göre düzeltilecek
  const startGame = async (startgameId) => {
    try {
      if (typeof ethereum !== "undefined") {
        const localS = localStorage.getItem(`startgame-${startgameId}-3`);
        const randomNum = Math.floor(Math.random() * 9000) + 1000;

        if (!localS) {
          const tx = await contractAndAuth.contract.startGame(startgameId);
          const receipt = await tx.wait();
          console.log("receipt:", receipt);

          contractAndAuth.contract.on(
            "RequestSent",
            async (requestId, numberOfWords) => {
              const reqResult = await contractAndAuth.contract.randomRequests(
                requestId
              );
              if (
                Number(reqResult.gameId) === startgameId &&
                Number(reqResult.requestType) === 3
              ) {
                console.log("RequestSent-startgame event was emmited");
                localStorage.setItem(`startgame-${startgameId}-3`, requestId);

                const tx = await contractAndAuth.vrf.mockFulfillment(
                  requestId,
                  [randomNum]
                ); // bir kez isteniyor. game.seed = 888777999 bu oluyor.
                const receipt = await tx.wait();
                console.log("mockFulfillment:", receipt);
                localStorage.clear(`startgame-${startgameId}-3`);
              }
            }
          );
        } else {
          if (Number(game[5]) === 1) {
            const tx = await contractAndAuth.vrf.mockFulfillment(localS, [
              randomNum,
            ]); // bir kez isteniyor. game.seed = 888777999 bu oluyor.
            const receipt = await tx.wait();
            console.log("mockFulfillment:", receipt);
            localStorage.clear(`startgame-${startgameId}-3`);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cancelGame = async (cancelledgameId) => {
    try {
      if (typeof ethereum !== "undefined") {
        const tx = await contractAndAuth.contract.cancelGame(cancelledgameId);
        const receipt = await tx.wait();
        console.log("receipt-cancelGame:", receipt);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("useEffect-> HostStartGame:", game, gameStatus, gameId);
    if (!game) {
      console.log("host - game:", game, gameId);
      setSeconds(initialSeconds <= 0 ? 0 : initialSeconds);
    }

    const countdown = setInterval(() => {
      if (seconds <= 0) {
        clearInterval(countdown);
        return;
      }
      setSeconds(seconds - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [seconds, game]);

  return (
    <div className="single-card-item number-card-item">
      <div className="in-box">
        <div className="time-area">
          <small>Game starts in</small>
          <p>
            {seconds / 3600 >= 1
              ? `more than 1 hour`
              : seconds < 60
              ? `00 : ${seconds < 10 ? `0${seconds}` : seconds}`
              : `${
                  Math.floor(seconds / 60) < 10
                    ? `0${Math.floor(seconds / 60)}`
                    : Math.floor(seconds / 60)
                } : ${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}`}
          </p>
        </div>
        <div className="btns-bottom mb-0">
          <Link
            to={search}
            className={seconds <= 0 ? "btn-sub" : "btn-sub done"}
            onClick={seconds <= 0 ? () => startGame(game[0]) : null}
          >
            {" "}
            Start Game
          </Link>
        </div>
        <div className="btns-bottom mb-0" style={{ marginTop: "10px" }}>
          <Link
            to={search}
            className={seconds > 0 ? "btn-sub" : "btn-sub done"}
            onClick={seconds > 0 ? () => cancelGame(game[0]) : null}
          >
            {" "}
            Cancel Game
          </Link>
        </div>
      </div>
      <img src={singleCardImg} className="" alt="" />
    </div>
  );
};

export default HostStartGame;
