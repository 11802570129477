import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cardRedraw } from "../../../pages/Game/store";
import { useLocation } from "react-router-dom";
import CloseButton from "../../CloseButton/CloseButton";
import Card0 from "../Cards/Card0";
import Card1 from "../Cards/Card1";
import Card2 from "../Cards/Card2";
import Card3 from "../Cards/Card3";
//images
import purpleNeonImg from "../../../assets/img/Purple-Neon.png";

const CardChange = ({
  wallet,
  contractAndAuth,
  gameId,
  game,
  gameStatus,
  checkReDrawCard,
  setCheckReDrawCard,
  onClose,
}) => {
  console.log("#-> CardChange(popup) rendered");
  const { search } = useLocation();
  const dispatch = useDispatch();
  const cardCount = useSelector((state) => state.gameStore.cardCount);

  useEffect(() => {
    // CONTRACT EVENTS
    if (contractAndAuth.contract) {
      contractAndAuth.contract.on("RequestSent", async (requestId) => {
        const reqResult = await contractAndAuth.contract.randomRequests(
          requestId
        );

        if (
          Number(reqResult.gameId) === gameId &&
          reqResult.player.toLowerCase() === wallet.accounts[0].toLowerCase() &&
          Number(reqResult.requestType) === 2
        ) {
          console.log("RequestSent-redrawCards event was emmited");
          localStorage.setItem(
            `redrawcard-${gameId}-${reqResult.player.toLowerCase()}-2`,
            `${requestId}`
          );

          dispatch(
            cardRedraw({
              gameId,
              contractAndAuth,
              wallet,
            })
          ).then((result) => {
            console.log("cardRedraw:", result.payload);
          });
        }
      });

      contractAndAuth.contract.on(
        "RequestFulfilled",
        async (requestId, reqType, player) => {
          const reqResult = await contractAndAuth.contract.randomRequests(
            requestId
          );
          if (
            Number(reqType) === 2 &&
            Number(reqResult.gameId) === gameId &&
            player.toLowerCase() === wallet.accounts[0].toLowerCase()
          ) {
            onClose();
          }
        }
      );
    }
  }, [contractAndAuth]);

  return (
    <div className="popup-wrapper always-wrapper">
      <CloseButton onClose={onClose} to={search} />
      <div className="in">
        <div className="text-top-area always-popup">Are you sure?</div>
        <div className="title-sub-box-o">
          Click the card you want to change! Chop-chop mam!
        </div>
        <div className="card-bottom-area">
          <div className="card-container">
            {cardCount >= 1 && (
              <Card0
                game={game}
                gameStatus={gameStatus}
                gameId={gameId}
                contractAndAuth={contractAndAuth}
                wallet={wallet}
                inComponent={"CardChange"}
                checkReDrawCard={checkReDrawCard}
                setCheckReDrawCard={setCheckReDrawCard}
              />
            )}
            {cardCount >= 2 && (
              <Card1
                game={game}
                gameStatus={gameStatus}
                gameId={gameId}
                contractAndAuth={contractAndAuth}
                wallet={wallet}
                inComponent={"CardChange"}
                checkReDrawCard={checkReDrawCard}
                setCheckReDrawCard={setCheckReDrawCard}
              />
            )}
            {cardCount >= 3 && (
              <Card2
                game={game}
                gameStatus={gameStatus}
                gameId={gameId}
                contractAndAuth={contractAndAuth}
                wallet={wallet}
                inComponent={"CardChange"}
                checkReDrawCard={checkReDrawCard}
                setCheckReDrawCard={setCheckReDrawCard}
              />
            )}
            {cardCount === 4 && (
              <Card3
                game={game}
                gameStatus={gameStatus}
                gameId={gameId}
                contractAndAuth={contractAndAuth}
                wallet={wallet}
                inComponent={"CardChange"}
                checkReDrawCard={checkReDrawCard}
                setCheckReDrawCard={setCheckReDrawCard}
              />
            )}
          </div>
        </div>
        <img src={purpleNeonImg} className="popup-bg" alt="" />
      </div>
    </div>
  );
};

export default CardChange;
