// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-how-to-play {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background-color: rgb(38 32 32 / 0%);
    backdrop-filter: blur(35px);
    -webkit-backdrop-filter: blur(35px);
    }
    
    .popup-how-to-play .in {
    position: fixed;
    left: 50%;
    transform: translate(-50%,-50%);
    top: 46%;
    z-index: 9;
    width: 100%;
    max-width: 1240px;
    }`, "",{"version":3,"sources":["webpack://./src/components/HowToPlay/HowToPlay.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,MAAM;IACN,WAAW;IACX,aAAa;IACb,YAAY;IACZ,oCAAoC;IACpC,2BAA2B;IAC3B,mCAAmC;IACnC;;IAEA;IACA,eAAe;IACf,SAAS;IACT,+BAA+B;IAC/B,QAAQ;IACR,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB","sourcesContent":[".popup-how-to-play {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 999;\n    background-color: rgb(38 32 32 / 0%);\n    backdrop-filter: blur(35px);\n    -webkit-backdrop-filter: blur(35px);\n    }\n    \n    .popup-how-to-play .in {\n    position: fixed;\n    left: 50%;\n    transform: translate(-50%,-50%);\n    top: 46%;\n    z-index: 9;\n    width: 100%;\n    max-width: 1240px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
