import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cardReveal, cardRedraw } from "../../../pages/Game/store";
import Card0 from "./Card0";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
//Popups
import HostRevealNumber from "../../../components/Game/HostGame/HostRevealNumber";
import HostStartGame from "../../../components/Game/HostGame/HostStartGame";
import CardsTxPopup from "../../../components/Game/Popups/CardsTx";
import CardChangePopup from "../../../components/Game/Popups/CardChange";

const Cards = ({
  game,
  gameStatus,
  wallet,
  contractAndAuth,
  drawnNumbers,
  hostDelay,
  lastRevealedNumber,
  cardChangePopup,
  setCardChangePopup,
}) => {
  console.log("#-> Cards rendered");
  const dispatch = useDispatch();
  const gameId = useSelector((state) => state.gameStore.gameId);
  const cardCount = useSelector((state) => state.gameStore.cardCount);
  const reqId = useSelector((state) => state.gameStore.reqId);

  const [checkDrawCard, setCheckDrawCard] = useState(null); // 1 revealed - 0 notready
  const [checkReDrawCard, setCheckReDrawCard] = useState(1); // 1 revealed - 0 notready
  const [hash, setHash] = useState(null);

  useEffect(() => {
    if (
      !contractAndAuth.isHost &&
      cardCount > 0 &&
      contractAndAuth.vrf &&
      gameStatus &&
      !hash
    ) {
      dispatch(
        cardReveal({
          gameId,
          contractAndAuth,
          wallet,
          cardCount,
          reqId,
        })
      ).then((result) => {
        if (result.payload) {
          console.log("cardReveal:", result.payload);
          setHash(result.payload.hash);
          if (result.payload.errors.includes("card not ready")) {
            dispatch(
              cardRedraw({
                gameId,
                contractAndAuth,
                wallet,
              })
            ).then((result) => {
              console.log("cardRedraw:", result.payload);
            });
          }
        }
      });
    }
  }, [contractAndAuth, gameStatus, hash]);

  useEffect(() => {
    // CONTRACT EVENTS
    if (contractAndAuth.contract) {
      contractAndAuth.contract.on(
        "RequestFulfilled",
        async (requestId, reqType, player, numberOfWords) => {
          const reqResult = await contractAndAuth.contract.randomRequests(
            requestId
          );
          if (Number(reqResult.gameId) === gameId && wallet.accounts[0]) {
            if (Number(reqType) === 1) {
              setCheckDrawCard(1); // render cards component (toggle)
            }

            if (Number(reqType) === 2) {
              if (player.toLowerCase() === wallet.accounts[0].toLowerCase()) {
                if (
                  localStorage.getItem(
                    `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
                  ) !== null
                ) {
                  setCheckReDrawCard(1); // render cards component (toggle)
                  localStorage.clear(
                    localStorage.getItem(
                      `redrawcard-${gameId}-${wallet.accounts[0].toLowerCase()}-2`
                    )
                  ); // kart değiştirme işlemi biter.
                }
              }
            }
          }
        }
      );
    }
  }, [contractAndAuth, gameStatus]);

  return (
    <>
      {hash && (
        <CardsTxPopup
          gameId={gameId}
          contractAndAuth={contractAndAuth}
          wallet={wallet}
          hash={hash}
          onClose={() => setHash(null)}
        />
      )}

      {cardChangePopup && (
        <CardChangePopup
          game={game}
          gameStatus={gameStatus}
          wallet={wallet}
          contractAndAuth={contractAndAuth}
          gameId={gameId}
          checkReDrawCard={checkReDrawCard}
          setCheckReDrawCard={setCheckReDrawCard}
          onClose={() => setCardChangePopup(false)}
        />
      )}

      <div className="card-container">
        {/* start game ekranı */}
        {contractAndAuth.isHost && Number(game[5]) < 2 && (
          <HostStartGame
            game={game}
            contractAndAuth={contractAndAuth}
            gameStatus={gameStatus}
          />
        )}
        {/* sayı çekme ekranı */}
        {contractAndAuth.isHost && Number(game[5]) > 1 && (
          <HostRevealNumber
            game={game}
            contractAndAuth={contractAndAuth}
            lastRevealedNumber={lastRevealedNumber}
            hostDelay={hostDelay}
            gameStatus={gameStatus}
          />
        )}

        {!contractAndAuth.isHost ? (
          <Fragment>
            <Card0
              gameId={gameId}
              gameStatus={gameStatus}
              contractAndAuth={contractAndAuth}
              wallet={wallet}
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              checkDrawCard={checkDrawCard}
              checkReDrawCard={checkReDrawCard}
              setCheckReDrawCard={setCheckReDrawCard}
            />
            <Card1
              gameId={gameId}
              gameStatus={gameStatus}
              contractAndAuth={contractAndAuth}
              wallet={wallet}
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              checkDrawCard={checkDrawCard}
              checkReDrawCard={checkReDrawCard}
              setCheckReDrawCard={setCheckReDrawCard}
            />
            <Card2
              gameId={gameId}
              gameStatus={gameStatus}
              contractAndAuth={contractAndAuth}
              wallet={wallet}
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              checkDrawCard={checkDrawCard}
              checkReDrawCard={checkReDrawCard}
              setCheckReDrawCard={setCheckReDrawCard}
            />
            <Card3
              gameId={gameId}
              gameStatus={gameStatus}
              contractAndAuth={contractAndAuth}
              wallet={wallet}
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              checkDrawCard={checkDrawCard}
              checkReDrawCard={checkReDrawCard}
              setCheckReDrawCard={setCheckReDrawCard}
            />
          </Fragment>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(Cards);
