import React, { useEffect, useState } from "react";
import "./BottomLine.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useMetaMask } from "../../utility/hooks/useMetaMask";

import Quests from "../Quests/Quests";
import DailyWheel from "../DailyWheel/DailyWheel";
import Leaderboard from "../Leaderboard/Leaderboard";
import HowToPlay from "../HowToPlay/HowToPlay";
import HostPanel from "../HostPanel/HostPanel";
import Warning from "../Warning/Warning";
//images
import hostPanelImg from "../../assets/img/host-panel.png";
import howToImg from "../../assets/img/how_to.png";

const BottomLine = (props) => {
  const [showQuest, setShowQuest] = useState(false);
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  const [showDailyWheel, setShowDailyWheel] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [showHostPanel, setShowHostPanel] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);

  const [countdown, setCountdown] = useState(86400);
  const [playtime, setPlaytime] = useState(false);
  const [warning, setWarning] = useState("");
  const [warnstatus, setWarnstatus] = useState(false);

  const { wallet, hasProvider, contractAndAuth } = useMetaMask();
  // Geri sayımı biçimli bir şekilde döndürme
  const formatCountdown = () => {
    const hours = Math.floor(countdown / 3600);
    const minutes = Math.floor((countdown % 3600) / 60);
    const seconds = countdown % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const checkPlaytime = () => {
    let data = {
      user: currentAccount,
    };
    console.log("play time kontrol ediliyor..." + playtime);
    axios
      .post("http://localhost:5000/api/general/checkplaytime", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log("play time response.data : " + response.data);
        if (response.data.status === "error") {
          // setPlaystatus(response.data.status);
          setCountdown(response.data.countdown);
          setWarnstatus(true);
          setWarning(
            "Hold up, you can't just come runnin' for the bonus!" +
              "Play one game, and have a blast, then we'll spin them wheels!"
          );
        } else {
          setCountdown(0);
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  };
  useEffect(() => {
    if (hasProvider && wallet.accounts.length > 0) {
      setCurrentAccount(wallet.accounts[0]);
    }
    //TODO: Hatalar dönüyor geçici olarak kapatıldı.
    // if (currentAccount) {
    //   checkPlaytime();
    // } else {
    //   setWarning("Account does not exists!");
    //   console.log("account bulunamadı", currentAccount);
    // }
    if (countdown === 0) return;
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Komponent kaldırıldığında zamanlayıcıyı temizle
    return () => clearInterval(timer);
  }, [wallet.accounts, currentAccount]);
  return (
    <>
      <Warning
        show={warnstatus}
        onClose={() => setWarnstatus(!warnstatus)}
        title="Warning"
        text={warning}
      />
      {/* how to play */}
      <HowToPlay
        show={showHowToPlay}
        onClose={() => setShowHowToPlay(!showHowToPlay)}
      />
      {/* how to play */}

      {/* host panel */}
      {contractAndAuth.isHost && (
        <HostPanel
          show={showHostPanel}
          onClose={() => setShowHostPanel(!showHostPanel)}
        />
      ) }
      {/* host panel */}
      {/* Jammy Quests */}
      <Quests show={showQuest} onClose={() => setShowQuest(!showQuest)} />
      {/* Jammy Quests */}
      {/* Daily Wheel */}
      {!playtime && (
        <DailyWheel
          show={showDailyWheel}
          onClose={() => setShowDailyWheel(!showDailyWheel)}
          currentAccount={currentAccount}
        />
      )}
      {/* Daily Wheel */}
      {/* leaderboard popup */}
      <Leaderboard
        show={showLeaderBoard}
        onClose={() => setShowLeaderBoard(!showLeaderBoard)}
      />
      {/* leaderboard popup */}

      {contractAndAuth.isHost && (
        <Link
          href="#"
          className="host-panel"
          onClick={() => setShowHostPanel(!showHostPanel)}
        >
          <img src={hostPanelImg} alt="" />
        </Link>
      )}
      <Link className="how-to" onClick={() => setShowHowToPlay(!showHowToPlay)}>
        <img src={howToImg} alt="" />
      </Link>
      <div className="bottom-line">
        <Link
          className="jammyQuests"
          onClick={() => setShowQuest(!showQuest)}
        />
        <Link
          className="leaderBoard"
          onClick={() => setShowLeaderBoard(!showLeaderBoard)}
        />
        <Link
          className="dailyWheel"
          onClick={() => {
            warning.length > 0
              ? setWarnstatus(true)
              :
            setShowDailyWheel(!showDailyWheel);
          }}
        />
        <div className="time-container">
          <span>{countdown > 0 ? formatCountdown() : '00:00:00'}</span>
        </div>
      </div>
    </>
  );
};

export default BottomLine;
