import React, { useState, useEffect, useRef } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { utils } from "ethers";
import "./index.css";
import ConnectWallet2 from "../../components/ConnectWallet2/ConnectWallet2";
import BottomLine from "../../components/BottomLine/BottomLine";
import niceSelect from "../../utility/niceSelect/niceSelectModule";
import { getFilterTimes, fetchGameList, gameFiltering } from "./store";
import { useDispatch } from "react-redux";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import Loading from "../../components/Loading/Loading";
//images
import levelImg from "../../assets/img/level-img.svg";
import logo from "../../assets/img/logo2.svg";
import ranksRight4Img from "../../assets/img/ranks-right-icon-4.png";
import level2Img from "../../assets/img/level-img-2.svg";
import maticImg from "../../assets/img/matic.svg";
import matic2Img from "../../assets/img/matic-icon.svg";
import prevImg from "../../assets/img/prev-btn.png";
import filterBgImg from "../../assets/img/filter-bg.png";
import userImg from "../../assets/img/user-icon.svg";
import bronzeImg from "../../assets/img/bronze-icon.svg";
import cardRowBgImg from "../../assets/img/card-row-bg.svg";

const Join = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wallet, contractAndAuth } = useMetaMask();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [gameList, setGameList] = useState([]);
  const [gameListLoading, setGameListLoading] = useState(false);
  const [cardsLength, setCardsLength] = useState(0); // soldout durumunu kontrol edebilmek için
  const [filterTimes, setFilterTimes] = useState([]);
  const [filterGameList, setFilterGameList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const filterGamePot = useRef();
  const filterGameCardPrice = useRef();
  const filterGameTime = useRef();
  const cardsCount = useRef();
  const [isNiceSelectLoad, setIsNiceSelectLoad] = useState(false);

  const cardsCountIncrease = (gameId, maxCardsPerPlayer) => {
    if (cardsCount[gameId].innerText < Number(maxCardsPerPlayer))
      cardsCount[gameId].innerText++;
  };

  const cardsCountDecrease = (gameId) => {
    if (cardsCount[gameId].innerText > 1) cardsCount[gameId].innerText--;
  };

  const applyFilter = () => {
    const pot = filterGamePot.current.value;
    const potmin = Number(pot.split("-")[0]);
    const potmax = Number(pot.split("-")[1]);
    const cardprice = Number(filterGameCardPrice.current.value);
    const time = filterGameTime.current.value.replace("-", " ");

    if (cardprice === 0 && time === "0" && pot === "0") {
      clearFilter();
    } else {
      dispatch(gameFiltering({ gameList, pot, cardprice, time })).then(
        (result) => {
          console.log("applyFilter:", result.payload);
          setIsFilter(true);
          setFilterGameList(result.payload);
        }
      );
    }
  };

  const clearFilter = () => {
    setFilterGameList([]);
    setIsFilter(false);
  };

  const joinGame = async (joingameId, cardscount, cardsprice) => {
    try {
      if (typeof ethereum !== "undefined") {
        if (contractAndAuth.isHost) {
          //TODO: navigate yönteminin doğru bir kullanım mı?
          navigate({
            pathname: "/game",
            search: `?gameId=${joingameId}`,
          });
        } else {
          // alert("joingameId: " + joingameId + " - cardscount: " + cardscount + " - cardsprice: " + cardsprice);
          const vl = Number(cardscount) * Number(cardsprice);
          const tx = await contractAndAuth.contract.joinGame(
            joingameId,
            cardscount,
            { value: utils.parseEther(vl.toString()) }
          );
          const receipt = await tx.wait();
          console.log("receipt:", receipt);

          contractAndAuth.contract.on(
            "RequestSent",
            async (requestId, numberOfWords) => {
              if (window.location.pathname !== "/game") {
                console.log("RequestSent-join event was emmited");
                console.log("requestId:", requestId.toString());
                console.log("numberOfWords:", numberOfWords.toString());

                // localStorage.setItem(`joined-${wallet.accounts[0]}`, `${joingameId},${numberOfWords},${requestId}`);

                // if(localStorage.getItem(`reqs-${wallet.accounts[0]}`) === null) {
                //   localStorage.setItem(`reqs-${wallet.accounts[0]}`, `${requestId}`);
                // }else{
                //   localStorage.setItem(`reqs-${wallet.accounts[0]}`, `${localStorage.getItem(`reqs-${wallet.accounts[0]}`)},${requestId}`);
                // }

                // if(localStorage.getItem(`cards-${wallet.accounts[0]}`) === null) {
                //   localStorage.setItem(`cards-${wallet.accounts[0]}`, `${numberOfWords}`);
                // }else{
                //   localStorage.setItem(`cards-${wallet.accounts[0]}`, `${localStorage.getItem(`cards-${wallet.accounts[0]}`)},${numberOfWords}`);
                // }

                //TODO: navigate yönteminin doğru bir kullanım mı?

                navigate({
                  pathname: "/game",
                  search: `?gameId=${joingameId}&cards=${numberOfWords}&reqId=${requestId}`,
                });
              }
            }
          );
        }
      }
    } catch (error) {
      if (error.message.search("CardsSoldOut") !== -1) {
        alert("This game Soldout!");
      }
      console.log(">>>", error.message);
    }
  };

  useEffect(() => {
    document.title = "Join Game - Jammy";
    console.log("useEffect: Loaded Join");

    //TODO: dispatch'ler async olmalı
    if (contractAndAuth.contract) {
      if (!filterTimes.length > 0) {
        dispatch(getFilterTimes({ searchParams })).then((result) => {
          console.log("Filter Times:", result.payload);
          setFilterTimes(result.payload);
        });
      } else {
        if (!isNiceSelectLoad) {
          niceSelect(filterGameTime);
          niceSelect(filterGamePot);
          niceSelect(filterGameCardPrice);
          setIsNiceSelectLoad(true);
        }
      }

      if (!gameList.length > 0 && filterTimes.length > 0 && isNiceSelectLoad) {
        setGameListLoading(true);
        setGameList([]);
        dispatch(fetchGameList({ searchParams, contractAndAuth, wallet })).then(
          (result) => {
            console.log("fetchGameList:", result.payload);
            setCardsLength(result.payload[0]);
            setGameList(result.payload[1]);
            setGameListLoading(false);
          }
        );
      }
    }

    // const interval = setInterval(() => {
    //   fetchGameList(
    //     searchParams.get("start"),
    //     searchParams.get("end"),
    //     searchParams.get("ap")
    //   );

    //   if (isAccountHost) {
    //     fetchGamesHost();
    //   }
    // }, 60000);
    // return () => clearInterval(interval);
  }, [isNiceSelectLoad, contractAndAuth, filterTimes.length]);

  return (
    <>
      <Link className="logo-join">
        <img src={logo} alt="" />
      </Link>
      <div className="price-area">
        <div className="level">
          <img src={levelImg} className="bg" alt="" />
          <span>LEVEL: NEWBY</span>
          <img src={ranksRight4Img} className="icon-right" alt="" />
        </div>
        <div className="level">
          <img src={level2Img} className="bg" alt="" />
          {wallet.balance ? (
            <span style={{ marginLeft: "-25px" }}>{wallet.balance} MATIC</span>
          ) : (
            <span>0 MATIC</span>
          )}
          <img src={maticImg} className="icon-matic" alt="" />
        </div>
      </div>
      <Link to="/" className="prev-btn-join">
        <img src={prevImg} alt="" />
        <span>
          Back to
          <br /> Lobby
        </span>
      </Link>

      <ConnectWallet2 />

      <div className="container-card">
        <div className="filter">
          <img src={filterBgImg} alt="" />
          <div className="filter-content">
            <div className="item-list">
              <span>Card:</span>
              <select ref={filterGameCardPrice} className="nice">
                <option value="0">ALL CARDS</option>
                <option value="2">BRONZE</option>
                <option value="6">SILVER</option>
                <option value="10">GOLD</option>
                <option value="15">DIAMOND</option>
              </select>
            </div>
            <div className="item-list">
              <span>Pot:</span>
              <select ref={filterGamePot} className="nice">
                <option value="0">ALL POTS</option>
                <option value="0-200">0-200</option>
                <option value="200-400">200-400</option>
                <option value="400-600">400-600</option>
                <option value="600-800">600-800</option>
                <option value="800-1000">800-1000</option>
                <option value="1000-1200">1000-1200</option>
                <option value="1200-1400">1200-1400</option>
                <option value="1400-1600">1400-1600</option>
              </select>
            </div>
            <div className="item-list">
              <span>Time:</span>
              <select ref={filterGameTime} className="nice">
                {filterTimes.length > 0 &&
                  filterTimes.map((time, index) => (
                    <option
                      value={time === "ALL TIMES" ? 0 : time.replace(" ", "-")}
                      key={index}
                    >
                      {time}
                    </option>
                  ))}
              </select>
            </div>
            <div className="item-list">
              <button onClick={() => applyFilter()} style={{ margin: 0 }}>
                Apply
              </button>
            </div>
            <div className="item-list">
              <button onClick={() => clearFilter()} style={{ margin: 0 }}>
                Clear
              </button>
            </div>
          </div>
        </div>
        <OverlayScrollbarsComponent
          className="scrollbarCustom content"
          options={{ scrollbars: { theme: "os-theme-dark" } }}
        >
          <div className="in">
            {gameListLoading ? <Loading text="Loading games..." /> : null}
            {/* item */}
            {!isFilter ? (
              gameList.map((gameItems, index) => (
                <div className="item" key={index}>
                  <div className="box-center-area">
                    <span className="time">{gameItems[0]}</span>
                    {/* TODO: Soldout durumunu ekle
                    https://github.com/knowndeveloper/jammy-react/blob/main/src/pages/Join.js line:872
                    {Number(gameItems[5]) + 1 > Number(cardsLength) / 2 ? (

                    )} */}
                    <div
                      className="join-btn"
                      onClick={() =>
                        !gameItems[6]
                          ? joinGame(
                              gameItems[2],
                              cardsCount[gameItems[2]].innerText,
                              gameItems[3]
                            )
                          : null
                      }
                    >
                      <span>{gameItems[6] ? "JOINED" : "JOIN"}</span>
                    </div>
                    <div className="info">
                      <div className="row-area">
                        <div className="icon">
                          <img src={matic2Img} alt="" />
                        </div>
                        <span>{gameItems[3] * gameItems[5]} MATIC</span>
                      </div>
                      <div className="row-area">
                        <div className="icon">
                          <img src={userImg} alt="" />
                        </div>
                        <span>--- People</span>
                      </div>
                      <div className="row-area">
                        <div className="icon">
                          <img src={bronzeImg} alt="" />
                        </div>
                        <span>{gameItems[1]}</span>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="left-btn">
                        <div
                          className="decrease"
                          onClick={() => cardsCountDecrease(gameItems[2])}
                        />
                        <div className="value">
                          <div
                            ref={(element) =>
                              (cardsCount[gameItems[2]] = element)
                            }
                            id={"cardscount-" + gameItems[2]}
                            className="input"
                          >
                            1
                          </div>
                          <span>CARD</span>
                        </div>
                        <div
                          className="increase"
                          onClick={() =>
                            cardsCountIncrease(gameItems[2], gameItems[4])
                          }
                        />
                      </div>
                      <div className="right-btn">
                        <small>{gameItems[3]}</small> <span>MATIC</span>
                      </div>
                    </div>
                  </div>
                  <img src={cardRowBgImg} className="item-bg" alt="" />
                </div>
              ))
            ) : filterGameList.length === 0 ? (
              <h4 style={{ marginTop: "130px" }}>Game not found!</h4>
            ) : (
              filterGameList.map((gameItems, index) => (
                <div className="item" key={index}>
                  <div className="box-center-area">
                    <span className="time">{gameItems[0]}</span>
                    <div
                      className="join-btn"
                      onClick={() =>
                        !gameItems[6]
                          ? joinGame(
                              gameItems[2],
                              cardsCount[gameItems[2]].innerText, //TODO : jquery kaldırıldı doğru çalıştığı kontrol edilmeli
                              gameItems[3]
                            )
                          : null
                      }
                    >
                      <span>{gameItems[6] ? "JOINED" : "JOIN"}</span>
                    </div>
                    <div className="info">
                      <div className="row-area">
                        <div className="icon">
                          <img src={matic2Img} alt="" />
                        </div>
                        <span>{gameItems[3] * gameItems[5]} MATIC</span>
                      </div>
                      <div className="row-area">
                        <div className="icon">
                          <img src={userImg} alt="" />
                        </div>
                        <span>--- People</span>
                      </div>
                      <div className="row-area">
                        <div className="icon">
                          <img src={bronzeImg} alt="" />
                        </div>
                        <span>{gameItems[1]}</span>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="left-btn">
                        <div
                          className="decrease"
                          onClick={() => cardsCountDecrease(gameItems[2])}
                        />
                        <div className="value">
                          <div
                            id={"cardscount-" + gameItems[2]}
                            className="input"
                          >
                            1
                          </div>
                          <span>CARD</span>
                        </div>
                        <div
                          className="increase"
                          onClick={() =>
                            cardsCountIncrease(gameItems[2], gameItems[4])
                          }
                        />
                      </div>
                      <div className="right-btn">
                        <small>{gameItems[3]}</small> <span>MATIC</span>
                      </div>
                    </div>
                  </div>
                  <img src={cardRowBgImg} className="item-bg" alt="" />
                </div>
              ))
            )}
            {/* item */}
          </div>
        </OverlayScrollbarsComponent>
      </div>
      <BottomLine />
    </>
  );
};

export default Join;
