// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BigNumber, utils } from "ethers";

const dateTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ap = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  if (hours === 12) {
    hours = 0;
  }
  let mergeTime =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    " " +
    ap;
  return mergeTime;
};

//TODO: Code iyileştirmesi yapılmalı
export const getFilterTimes = createAsyncThunk(
  "getFilterTimes",
  async (params) => {
    const startParam = params.searchParams.get("start");
    const endParam = params.searchParams.get("end");
    const apParam = params.searchParams.get("ap");
    const nowtimestamp = Math.floor(Date.now() / 1000);
    let filterNowHour = dateTime(new Date((nowtimestamp + 60) * 1000))
      .split(" ")[0]
      .split(":")[0];
    let filterNowMin = dateTime(new Date((nowtimestamp + 60) * 1000))
      .split(" ")[0]
      .split(":")[1];
    // console.log(filterNowHour);
    // console.log(filterNowMin);
    let filtertimes = ["ALL TIMES"];
    while (true) {
      if (filterNowMin <= 59) {
        filtertimes.push(
          `${filterNowHour.toString().padStart(2, "0")}:${filterNowMin
            .toString()
            .padStart(2, "0")} ${apParam}`
        );
        filterNowMin++;
      } else {
        filterNowMin = 0;
        if (filterNowHour < endParam) {
          filterNowHour++;
        } else {
          break;
        }
      }
    }
    return filtertimes;
  }
);

//TODO: Code iyileştirmesi yapılmalı
export const fetchGameList = createAsyncThunk(
  "fetchGameList",
  async (params) => {
    const start = params.searchParams.get("start");
    const end = params.searchParams.get("end");
    const ap = params.searchParams.get("ap");

    const nowtimestamp = Math.floor(Date.now() / 1000);
    let gameCounter = Number(
      await params.contractAndAuth.contract.gameCounter()
    );
    let list = [];

    let cardslength = 0;
    try {
      //TODO: Kart uzunluğu apiden alınacak (200)
      for (cardslength; cardslength < 200; cardslength++) {
        const cards = await params.contractAndAuth.contract.cards(cardslength);
      }
    } catch (err) {
      console.log("err > (cardslength):", err.code);
    }

    for (gameCounter; gameCounter > 0; gameCounter--) {
      const gameStatus = await params.contractAndAuth.contract.gameStatus(gameCounter);
      
      if (gameStatus === 1) {
        const games = await params.contractAndAuth.contract.games(gameCounter);
        let isJoinedGame = 0;
        params.contractAndAuth.contract
          .playerCards(gameCounter, params.wallet.accounts[0], 0)
          .then(() => {
            isJoinedGame = 1;
          })
          .catch((err) => {
            //console.log("catch",err);
          });

        const gametimestamp = Number(games[1]);
        const dateFormatGame = new Date(gametimestamp * 1000);
        const gamecancelled = games[7]; //cancelled
        let gamecardprice = Number(
          (+utils.formatEther(BigNumber.from(games[3]))).toFixed(0)
        );
        let maxCardsPerPlayer = Number(games[2]);
        let totalCardsSold = Number(games[4]); // soldout durumunu kontrol et

        const gameap = dateTime(dateFormatGame).split(" ")[1];
        let gamehours = Number(
          dateTime(dateFormatGame).split(" ")[0].split(":")[0]
        );

        if (
          nowtimestamp < gametimestamp &&
          gamecancelled === false &&
          gamehours >= start &&
          gamehours <= end &&
          gameap === ap
        ) {
          // or gameStatus === 1
          switch (gamecardprice) {
            case 2:
              list.push([
                dateTime(dateFormatGame),
                "BRONZE",
                gameCounter,
                gamecardprice,
                maxCardsPerPlayer,
                totalCardsSold,
                isJoinedGame,
              ]);
              break;

            case 6:
              list.push([
                dateTime(dateFormatGame),
                "SILVER",
                gameCounter,
                gamecardprice,
                maxCardsPerPlayer,
                totalCardsSold,
                isJoinedGame,
              ]);
              break;

            case 10:
              list.push([
                dateTime(dateFormatGame),
                "GOLD",
                gameCounter,
                gamecardprice,
                maxCardsPerPlayer,
                totalCardsSold,
                isJoinedGame,
              ]);
              break;

            case 15:
              list.push([
                dateTime(dateFormatGame),
                "DIAMOND",
                gameCounter,
                gamecardprice,
                maxCardsPerPlayer,
                totalCardsSold,
                isJoinedGame,
              ]);
              break;
          }
        }
      }
    }
    list.sort();
    return [cardslength, list];
  }
);

//TODO: Code iyileştirmesi yapılmalı
export const gameFiltering = createAsyncThunk(
  "gameFiltering",
  async (params) => {
    let filterArry = [];
    const potmin = Number(params.pot.split("-")[0]);
    const potmax = Number(params.pot.split("-")[1]);

    params.gameList.map((gameItems) => {
      if (
        gameItems[3] === params.cardprice &&
        params.time === "0" &&
        params.pot === "0"
      ) {
        filterArry.push(gameItems);
      } else if (
        gameItems[0] === params.time &&
        params.cardprice === 0 &&
        params.pot === "0"
      ) {
        filterArry.push(gameItems);
      } else if (
        gameItems[5] >= potmin &&
        gameItems[5] <= potmax &&
        params.cardprice === 0 &&
        params.time === "0"
      ) {
        filterArry.push(gameItems);
      } else if (
        gameItems[3] === params.cardprice &&
        gameItems[0] === params.time &&
        params.pot === "0"
      ) {
        filterArry.push(gameItems);
      } else if (
        gameItems[3] === params.cardprice &&
        gameItems[5] >= potmin &&
        gameItems[5] <= potmax &&
        params.time === "0"
      ) {
        filterArry.push(gameItems);
      } else if (
        gameItems[0] === params.time &&
        gameItems[5] >= potmin &&
        gameItems[5] <= potmax &&
        params.cardprice === 0
      ) {
        filterArry.push(gameItems);
      } else if (
        gameItems[3] === params.cardprice &&
        gameItems[0] === params.time &&
        gameItems[5] >= potmin &&
        gameItems[5] <= potmax
      ) {
        filterArry.push(gameItems);
      }
    });
    return filterArry;
  }
);

export const joinStore = createSlice({
  name: "joinStore",
  initialState: {},
  reducers: {},
});

export default joinStore.reducer;
