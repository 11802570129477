import React, { useEffect, useRef, useState } from "react";
import "./HostPanel.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { utils } from "ethers";
import { Link } from "react-router-dom";
import CloseButton from "../CloseButton/CloseButton";
import { useDispatch } from "react-redux";
import niceSelect from "../../utility/niceSelect/niceSelectModule";
import { createGameTimes, fetchGamesHost } from "../../pages/store";
//images
import hostPanelBg from "../../assets/img/hostpanel-bg.png";
import { useMetaMask } from "../../utility/hooks/useMetaMask";

const HostPanel = (props) => {
  const { contractAndAuth } = useMetaMask();
  const [hostAllGameSchedule, setHostAllGameSchedule] = useState([]);
  const [createTimes, setCreateTimes] = useState([]);
  const createGameAPTimeRef = useRef();
  const createGameCardPriceRef = useRef();

  const dispatch = useDispatch();

  const createGame = async () => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const selectedTime = createGameAPTimeRef.current.value;
        const selectedPrice = createGameCardPriceRef.current.value;
        const hoursminutes = selectedTime.split("-")[0];
        const ap = selectedTime.split("-")[1];
        const today = new Date().toLocaleDateString("tr");

        let timestamp =
          Date.parse(
            `${today.split(".")[1]}/${today.split(".")[0]}/${
              today.split(".")[2]
            } ${hoursminutes}:00 ${ap}`,
            `yyy-dd-mm HH:mm:ss`
          ) / 1000;

        const newGame = {
          startDate: timestamp,
          maxCardsPerPlayer: 4,
          cardPrice: utils.parseEther(selectedPrice),
          houseShare: 1000, // 10%
          prizes: [
            4000, // 40%
            2000, // 20%
            1500, // 15%
            1000, // 10%
            500, // 5%
          ],
        };

        const tx = await contractAndAuth.contract.createGame(
          newGame.startDate,
          newGame.maxCardsPerPlayer,
          newGame.cardPrice,
          newGame.houseShare,
          newGame.prizes
        );
        const receipt = await tx.wait();
        //console.log("receipt Game:", receipt);

        contractAndAuth.contract.on("GameCreated", async (host, gameId) => {
          console.log("(HostPanel): GameCreated event was emmited");
          dispatch(fetchGamesHost({ contractAndAuth })).then((result) => {
            console.log("games:", result.payload);
            setHostAllGameSchedule(result.payload);
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cancelGame = async (cancelledgameId) => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const tx = await contractAndAuth.contract.cancelGame(cancelledgameId);
        const receipt = await tx.wait();
        // console.log("receipt:", receipt);

        contractAndAuth.contract.on("GameCancelled", async (gameId) => {
          console.log("(HostPanel): GameCancelled event was emmited");
          dispatch(fetchGamesHost({ contractAndAuth })).then((result) => {
            setHostAllGameSchedule(result.payload);
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const startGame = async (startgameId) => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const tx = await contractAndAuth.contract.startGame(startgameId);
        const receipt = await tx.wait();
        //console.log("receipt:", receipt);

        contractAndAuth.contract.on("GameStarted", async (gameId) => {
          console.log("(HostPanel): GameStarted event was emmited");
          dispatch(fetchGamesHost({ contractAndAuth })).then((result) => {
            setHostAllGameSchedule(result.payload);
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //TODO: createGameTimes & fetchGamesHost için memorization yapılmalı
  useEffect(() => {
    if (props.show && contractAndAuth.contract) {
      //TODO: time çok olduğu zaman popup geç açılıyor (Muhtmelen popup açılmak için selecte render edilmesini bekliyor)
      dispatch(createGameTimes())
        .then((result) => {
          setCreateTimes(result.payload);
          // console.log("times:", result.payload);
          return true;
        })
        .then((result) => {
          if (result) { // && createGameAPTimeRef.current.value && createGameCardPriceRef.current.value
            niceSelect(createGameAPTimeRef);
            niceSelect(createGameCardPriceRef);
          }
        });

      dispatch(fetchGamesHost({ contractAndAuth }))
        .then((result) => {
          //console.log("games:", result.payload);
          setHostAllGameSchedule(result.payload);
        });
    }
  }, [contractAndAuth, props.show]);

  return (
    <>
      {props.show && (
        <div className="popup-host-panel popup">
          <CloseButton onClose={props.onClose} />
          <div className="in">
            <img src={hostPanelBg} className="w-100" alt="" />
            <div className="box-in">
              <div className="left-colm">
                <h3>Create a New Game</h3>
                <h4>Create a New Game</h4>
                <p>
                  Specify the time <br />
                  you want the game to start.
                </p>
                <div className="selects-area">
                  <select ref={createGameAPTimeRef} className="nice">
                    {createTimes.length > 0 &&
                      createTimes.map((time, index) => (
                        <option value={time.replace(" ", "-")} key={index}>
                          {time}
                        </option>
                      ))}
                  </select>

                  <select ref={createGameCardPriceRef} className="nice">
                    <option value="2">BRONZE</option>
                    <option value="6">SILVER</option>
                    <option value="10">GOLD</option>
                    <option value="15">DIAMOND</option>
                  </select>

                  <div className="btns-bottom justify-content-start">
                    <Link
                      onClick={() => createGame()}
                      className="btn-sub"
                      style={{ marginLeft: 0 }}
                    >
                      Create
                    </Link>
                  </div>
                </div>
              </div>
              <div className="right-colm ">
                <h3>
                  Game Schedule <span>Game Schedule</span>
                </h3>
                <div className="right-in ">
                  <OverlayScrollbarsComponent
                    className="item-list-wrapper scrollbarCustom content"
                    options={{ scrollbars: { theme: "os-theme-dark" } }}
                  >
                    {hostAllGameSchedule.map((gameItems, index) => (
                      <div key={index} className="item-row">
                        <div className="time">{gameItems[1]}</div>
                        <div className="name">{gameItems[2]}</div>
                        <div className="btns-area">
                          {gameItems[4] === 1 ? (
                            <Link
                              href="#"
                              className="cancel"
                              onClick={() => cancelGame(gameItems[3])}
                            >
                              Cancel
                            </Link>
                          ) : null}
                          {gameItems[4] === 2 ? (
                            <Link
                              href="#"
                              className="start"
                              onClick={() => startGame(gameItems[3])}
                            >
                              Start
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </OverlayScrollbarsComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HostPanel;
