import React, { useState, useEffect } from "react";
import "./index.css";
import { BigNumber, utils } from "ethers";
import { Link, useLocation } from "react-router-dom";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import { urlParams, fetchGame, checkGamePrizes, checkNumbers } from "./store";
import { checkGameStatus } from "../store";
import { useDispatch } from "react-redux";
import BottomButtons from "../../components/Game/BottomButtons/BottomButtons";
import Cards from "../../components/Game/Cards/Cards";
import TopInfo from "../../components/Game/TopInfo/TopInfo";
import RightLine from "../../components/Game/RightLine/RightLine";
import LeftLine from "../../components/Game/LeftLine/LeftLine";
import ConnectWallet2 from "../../components/ConnectWallet2/ConnectWallet2";
// Popups
import PrizePopup from "../../components/Game/Popups/Prizes";
import WinnerLoserPopup from "../../components/Game/Popups/WinnerLoserPopup";
// Images
import logo from "../../assets/img/logo.svg";

function Game() {
  console.count("#-> Game rendered");
  const { search } = useLocation();
  const { wallet, contractAndAuth } = useMetaMask();
  const dispatch = useDispatch();
  const [searchParams] = useState(new URLSearchParams(search));
  const [game, setGame] = useState([]);
  const [gameStatus, setGameStatus] = useState(0);
  const [gameId, setGameId] = useState(0);
  const [cardCount, setCartCount] = useState(0);
  const [reqId, setReqId] = useState(0);

  const [headNumbers, setHeadNumbers] = useState([]);
  const [drawnNumbers, setDrawnNumbers] = useState([]);
  const [passiveNumbers, setPassiveNumbers] = useState([]);
  const [lastRevealedNumber, setLastRevealedNumber] = useState(0);
  const [hostDelay, setHostDelay] = useState(0);

  const [cardChangePopup, setCardChangePopup] = useState(false);
  const [prizePopupParams, setPrizePopupParams] = useState([]);
  const [showWinnerLoserPopup, setShowWinnerLoserPopup] = useState(false);
  const [prizesClaimed, setPrizesClaimed] = useState(false);

  const prizesSummary = async (totalpot) => {
    console.log("Total Pot (totalAmount):", totalpot); // %100
    const pendingFunds = Number(
      (+utils.formatEther(
        BigNumber.from(await contractAndAuth.contract.pendingFunds())
      )).toFixed(2)
    );
    const games = await contractAndAuth.contract.games(gameId);
    // const gamecardprice = Number((+utils.formatEther(BigNumber.from(games[3]))).toFixed(0));
    // const totalCardsSold = Number(games[4]);

    const houseShare = Number(games[5]); // totalpot un %10
    const team1Shares = Number(await contractAndAuth.contract.team1Shares());
    const team2Shares = Number(await contractAndAuth.contract.team2Shares());
    const team1Address = await contractAndAuth.contract.team1Address();
    const team2Address = await contractAndAuth.contract.team2Address();
    const jam1Share = (await contractAndAuth.contract.gamePrizes(gameId, 4))[0];
    const jam2Share = (await contractAndAuth.contract.gamePrizes(gameId, 3))[0];
    const jam3Share = (await contractAndAuth.contract.gamePrizes(gameId, 2))[0];
    const jam4Share = (await contractAndAuth.contract.gamePrizes(gameId, 1))[0];
    const jammyShare = (
      await contractAndAuth.contract.gamePrizes(gameId, 0)
    )[0];

    const houseAmount =
      (Number(totalpot) * houseShare) / contractAndAuth.precisionBasis;
    const team1Amount =
      (houseAmount * team1Shares) / contractAndAuth.precisionBasis;
    const team2Amount =
      (houseAmount * team2Shares) / contractAndAuth.precisionBasis;
    const jam1PrizeAmount =
      (Number(totalpot) * jam1Share) / contractAndAuth.precisionBasis;
    const jam2PrizeAmount =
      (Number(totalpot) * jam2Share) / contractAndAuth.precisionBasis;
    const jam3PrizeAmount =
      (Number(totalpot) * jam3Share) / contractAndAuth.precisionBasis;
    const jam4PrizeAmount =
      (Number(totalpot) * jam4Share) / contractAndAuth.precisionBasis;
    const jammyPrizeAmount =
      (Number(totalpot) * jammyShare) / contractAndAuth.precisionBasis;

    console.log(`houseShare (%${houseShare / 100}):`, houseAmount);
    console.log(
      `team1Amount (%${team1Shares / 1000}):`,
      team1Amount,
      `-> (${team1Address.slice(0, 6)}...${team1Address.slice(
        team1Address.length - 4,
        team1Address.length
      )})`
    );
    console.log(
      `team2Amount (%${team2Shares / 1000}):`,
      team2Amount,
      `-> (${team2Address.slice(0, 6)}...${team2Address.slice(
        team2Address.length - 4,
        team2Address.length
      )})`
    );
    console.log(`jam1 PrizeAmount (%${jam1Share / 100}):`, jam1PrizeAmount);
    console.log(`jam2 PrizeAmount (%${jam2Share / 100}):`, jam2PrizeAmount);
    console.log(`jam3 PrizeAmount (%${jam3Share / 100}):`, jam3PrizeAmount);
    console.log(`jam4 PrizeAmount (%${jam4Share / 100}):`, jam4PrizeAmount);
    console.log(`jammy PrizeAmount (%${jammyShare / 100}):`, jammyPrizeAmount);

    let jam1Winners = [];
    let jam2Winners = [];
    let jam3Winners = [];
    let jam4Winners = [];
    let jammyWinners = [];
    for (let prizeIndex = 4; prizeIndex >= 0; prizeIndex--) {
      try {
        for (let winnerIndex = 0; ; winnerIndex++) {
          switch (prizeIndex) {
            case 4:
              jam1Winners.push(
                await contractAndAuth.contract.prizeWinners(
                  gameId,
                  prizeIndex,
                  winnerIndex
                )
              );
              break;

            case 3:
              jam2Winners.push(
                await contractAndAuth.contract.prizeWinners(
                  gameId,
                  prizeIndex,
                  winnerIndex
                )
              );
              break;

            case 2:
              jam3Winners.push(
                await contractAndAuth.contract.prizeWinners(
                  gameId,
                  prizeIndex,
                  winnerIndex
                )
              );
              break;

            case 1:
              jam4Winners.push(
                await contractAndAuth.contract.prizeWinners(
                  gameId,
                  prizeIndex,
                  winnerIndex
                )
              );
              break;

            case 0:
              jammyWinners.push(
                await contractAndAuth.contract.prizeWinners(
                  gameId,
                  prizeIndex,
                  winnerIndex
                )
              );
              break;
          }
        }
      } catch (error) {
        // console.log(error);
      }
    }

    jam1Winners.forEach((winner) => {
      console.log(
        `Jam1 Winner (${winner.slice(0, 6)}...${winner.slice(
          winner.length - 4,
          winner.length
        )}):`,
        jam1PrizeAmount / jam1Winners.length,
        `(${jam1PrizeAmount}/${jam1Winners.length})`
      );
    });
    jam2Winners.forEach((winner) => {
      console.log(
        `Jam2 Winner (${winner.slice(0, 6)}...${winner.slice(
          winner.length - 4,
          winner.length
        )}):`,
        jam2PrizeAmount / jam2Winners.length,
        `(${jam2PrizeAmount}/${jam2Winners.length})`
      );
    });
    jam3Winners.forEach((winner) => {
      console.log(
        `Jam3 Winner (${winner.slice(0, 6)}...${winner.slice(
          winner.length - 4,
          winner.length
        )}):`,
        jam3PrizeAmount / jam3Winners.length,
        `(${jam3PrizeAmount}/${jam3Winners.length})`
      );
    });
    jam4Winners.forEach((winner) => {
      console.log(
        `Jam4 Winner (${winner.slice(0, 6)}...${winner.slice(
          winner.length - 4,
          winner.length
        )}):`,
        jam4PrizeAmount / jam4Winners.length,
        `(${jam4PrizeAmount}/${jam4Winners.length})`
      );
    });
    jammyWinners.forEach((winner) => {
      console.log(
        `Jammy Winner (${winner.slice(0, 6)}...${winner.slice(
          winner.length - 4,
          winner.length
        )}):`,
        jammyPrizeAmount / jammyWinners.length,
        `(${jammyPrizeAmount}/${jammyWinners.length})`
      );
    });

    console.log("pendingFunds:", pendingFunds);

    if (contractAndAuth.contractBalance) {
      console.log(
        "Contract Balance:",
        Number(
          (+utils.formatEther(
            BigNumber.from(contractAndAuth.contractBalance)
          )).toFixed(2)
        )
      );
    }
  };

  const claimRefund = async () => {
    if (gameStatus === 5 || gameStatus === 6) {
      try {
        const tx = await contractAndAuth.contract.claimRefund(gameId);
        const receipt = await tx.wait();
        console.log("receipt-claimRefund:", receipt);
      } catch (error) {
        // const provider = new providers.JsonRpcProvider(window.ethereum);
        // console.log("provider:", provider.emit(error));
        console.error(error.reason);
      }
    } else {
      console.log(`wrongGameStatus (current: ${gameStatus})`);
    }
  };

  const contractEvents = async () => {
    gameStatus === 1 &&
      contractAndAuth.contract.on(
        "PlayerJoined",
        async (joinGameId, player, cardsCount) => {
          if (Number(joinGameId) === gameId) {
            console.log("# emmited (START): PlayerJoined");
            console.log("joinGameId:", joinGameId.toString());
            console.log("player:", player.toString());
            console.log("cardsCount:", cardsCount.toString());

            dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
              (result) => {
                if (result.payload) {
                  console.log(">>> gameStatus:", result.payload);
                  setGameStatus(result.payload);
                }
              }
            );

            dispatch(fetchGame({ gameId, contractAndAuth })).then((result) => {
              if (result.payload.length > 0) {
                setGame(result.payload);
              }
            });

            console.log("# emmited (END): PlayerJoined");
          }
        }
      );

    (gameStatus === 1 || gameStatus === 3) &&
      contractAndAuth.contract.on(
        "RequestFulfilled",
        async (requestId, reqType, player) => {
          const reqResult = await contractAndAuth.contract.randomRequests(
            requestId
          );
          if (Number(reqResult.gameId) === gameId) {
            console.log("# emmited (START): RequestFulfilled");
            console.log("reqResult.gameId:", reqResult.gameId.toString());
            console.log("requestId:", requestId.toString());
            console.log("reqType:", reqType.toString());
            console.log("player:", player.toString());

            if (Number(reqType) === 3) {
              const reqResult = await contractAndAuth.contract.randomRequests(
                requestId
              );

              if (Number(reqResult.gameId) === gameId) {
                dispatch(fetchGame({ gameId, contractAndAuth })).then(
                  (result) => {
                    if (result.payload.length > 0) {
                      setGame(result.payload);
                    }
                  }
                );

                dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
                  (result) => {
                    if (result.payload) {
                      setGameStatus(result.payload);
                    }
                  }
                );

                dispatch(
                  checkNumbers({ gameId, contractAndAuth, gameStatus })
                ).then((result) => {
                  if (result.payload) {
                    setHeadNumbers(result.payload.headNumbers);
                    setDrawnNumbers(result.payload.drawnNumbers);
                    setPassiveNumbers(result.payload.passiveNumbers);
                    setLastRevealedNumber(result.payload.lastRevealedNumber);
                    setHostDelay(result.payload.hostDelay);
                  }
                });
              }
            }
            console.log("# emmited (END): RequestFulfilled");
          }
        }
      );

    (gameStatus === 2 || gameStatus === 3) &&
      contractAndAuth.contract.on("GameStarted", async (startedGameId) => {
        if (Number(startedGameId) === gameId) {
          console.log("# emmited (START): GameStarted");
          console.log("startedGameId:", startedGameId.toString());

          dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
            (result) => {
              if (result.payload) {
                console.log(">>> gameStatus:", result.payload);
                setGameStatus(result.payload);
              }
            }
          );

          console.log("# emmited (END): GameStarted");
        }
      });

    gameStatus === 3 &&
      contractAndAuth.contract.on(
        "NumberRevealed",
        async (nrGameId, revealedNumber) => {
          //TODO : Contract - gameStatus durumlarını kontrol et, locals leri clear yapacaksın
          if (Number(nrGameId) === gameId) {
            console.log("revealedNumber:", revealedNumber.toString());

            let localRN = localStorage.getItem(`revealNumbers-${gameId}`);
            if (localRN) {
              if (Number(localRN.split(",")[0]) !== Number(revealedNumber)) {
                localStorage.setItem(
                  `revealNumbers-${gameId}`,
                  `${revealedNumber},${localRN}`
                );
              }
            } else {
              localStorage.setItem(`revealNumbers-${gameId}`, revealedNumber);
            }

            let headNumbers = [];
            if (localStorage.getItem(`revealNumbers-${gameId}`)) {
              for (
                let i = 0;
                i <
                localStorage.getItem(`revealNumbers-${gameId}`).split(",")
                  .length;
                i++
              ) {
                if (i < 5) {
                  headNumbers.push(
                    localStorage.getItem(`revealNumbers-${gameId}`).split(",")[
                      i
                    ]
                  );
                } else {
                  break;
                }
              }
            }
            setHeadNumbers(headNumbers);
            setDrawnNumbers((oldDrawnNumbers) => [
              ...oldDrawnNumbers,
              !oldDrawnNumbers.includes(Number(revealedNumber)) &&
                Number(revealedNumber),
            ]);
            setPassiveNumbers((oldPassiveNumbers) =>
              oldPassiveNumbers.filter(
                (number) => number !== Number(revealedNumber)
              )
            );
            setLastRevealedNumber(Number(revealedNumber));
            setHostDelay(1);
          }
        }
      );

    gameStatus === 3 &&
      contractAndAuth.contract.on(
        "PrizeWon",
        async (prizeWonGameId, prizeIndex, winner) => {
          if (Number(prizeWonGameId) === gameId) {
            console.log("# emmited (START): PrizeWon");
            console.log("prizeWonGameId:", prizeWonGameId.toString());
            console.log("prizeIndex:", prizeIndex.toString());
            console.log("winner:", winner.toString());

            dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
              (result) => {
                if (result.payload) {
                  console.log(">>> gameStatus:", result.payload);
                  setGameStatus(result.payload);
                }
              }
            );

            Number(prizeIndex) !== 0 &&
              setPrizePopupParams([Number(prizeIndex), [winner]]);
            console.log("# emmited (END): PrizeWon");
          }
        }
      );

    (gameStatus === 3 || gameStatus === 4) &&
      contractAndAuth.contract.on("GameEnds", async (endsGameId) => {
        if (Number(endsGameId) === gameId) {
          console.log("# emmited (START): GameEnds");
          console.log("endsGameId:", endsGameId.toString());

          dispatch(checkGamePrizes({ gameId, contractAndAuth })).then(
            // jammy ödülü won u true yapar
            (result) => {
              console.log(result.payload);
            }
          );

          dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
            (result) => {
              if (result.payload) {
                console.log(">>> gameStatus:", result.payload);
                setGameStatus(result.payload);
              }
            }
          );

          let winners = [];
          try {
            for (let i = 0; ; i++) {
              winners.push(
                await contractAndAuth.contract.prizeWinners(gameId, 0, i)
              );
            }
          } catch (error) {
            console.log(">>> err: gameEnd event");
            setPrizePopupParams([0, winners]); // jammy popup
          }

          console.log("# emmited (END): GameEnds");
        }
      });

    gameStatus === 4 &&
      contractAndAuth.contract.on(
        "PrizeCollected",
        async (prizeCollectedGameId, totalAmount) => {
          if (Number(prizeCollectedGameId) === gameId) {
            console.log("# emmited (START): PrizeCollected");
            console.log(
              "prizeCollectedGameId:",
              prizeCollectedGameId.toString()
            );
            console.log("totalAmount:", totalAmount.toString());

            dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
              (result) => {
                if (result.payload) {
                  console.log(">>> gameStatus:", result.payload);
                  setGameStatus(result.payload);
                }
              }
            );

            dispatch(fetchGame({ gameId, contractAndAuth })).then((result) => {
              if (result.payload.length > 0) {
                console.log("game:", result.payload);
                setGame(result.payload);
                localStorage.clear();
              }
            });

            prizesSummary(
              Number(
                (+utils.formatEther(BigNumber.from(totalAmount))).toFixed(0)
              )
            );

            setPrizesClaimed(true);
            setShowWinnerLoserPopup(true); //TODO: popup kapanışında lobby yönlendirilecek

            console.log("# emmited (END): PrizeCollected");
          }
        }
      );

    gameStatus !== 0 &&
      contractAndAuth.contract.on(
        "RefundSent",
        async (refundSentGameId, account, amount) => {
          if (Number(refundSentGameId) === gameId && wallet.accounts[0]) {
            if (wallet.accounts[0].toLowerCase() === account.toLowerCase()) {
              console.log("# emmited (START): RefundSent");
              console.log("refundSentGameId:", refundSentGameId.toString());
              console.log("account:", account.toString());
              console.log("amount:", amount.toString());

              dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
                (result) => {
                  if (result.payload) {
                    console.log(">>> gameStatus:", result.payload);
                    setGameStatus(result.payload);
                  }
                }
              );

              console.log("# emmited (END): RefundSent");
            }
          }
        }
      );

    gameStatus !== 0 &&
      contractAndAuth.contract.on("GameCancelled", async (cancelledGameId) => {
        if (Number(cancelledGameId) === gameId) {
          console.log("# emmited (START): GameCancelled");
          console.log("cancelledGameId:", cancelledGameId.toString());

          dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
            (result) => {
              if (result.payload) {
                console.log(">>> gameStatus:", result.payload);
                setGameStatus(result.payload);
              }
            }
          );

          claimRefund();
          console.log("# emmited (END): GameCancelled");
        }
      });
  };

  useEffect(() => {
    console.count("useEffect-> Game");
    if (typeof window.ethereum !== "undefined") {
      if (!gameId || !cardCount || !reqId) {
        dispatch(urlParams({ searchParams })).then((result) => {
          setGameId(result.payload.gameId);
          setCartCount(result.payload.cardCount);
          setReqId(result.payload.reqId);
        });
      }

      try {
        if (
          gameId > 0 &&
          wallet.accounts.length > 0 &&
          contractAndAuth.contract
        ) {
          dispatch(fetchGame({ gameId, contractAndAuth })).then((result) => {
            if (result.payload.length > 0) {
              console.log("game:", result.payload);
              setGame(result.payload);
            }
          });

          dispatch(checkGameStatus({ gameId, contractAndAuth })).then(
            (result) => {
              if (result.payload) {
                console.log("gameStatus:", result.payload);
                setGameStatus(result.payload);
              }
            }
          );

          if (gameStatus === 1) {
            console.log(">>> GAME CREATED");
          } else if (gameStatus === 2) {
            console.log(">>> GAME HAS NOT STARTED YET");
          } else if (gameStatus === 3 && game[5] > 1) {
            console.log(">>> GAME STARTED");
            dispatch(
              checkNumbers({ gameId, contractAndAuth, gameStatus })
            ).then((result) => {
              if (result.payload) {
                setHeadNumbers(result.payload.headNumbers);
                setDrawnNumbers(result.payload.drawnNumbers);
                setPassiveNumbers(result.payload.passiveNumbers);
                setLastRevealedNumber(result.payload.lastRevealedNumber);
                setHostDelay(result.payload.hostDelay);
              }
            });
          } else if (gameStatus === 4) {
            console.log(">>> GAME ENDED");
          } else if (gameStatus === 5) {
            console.log(">>> GAME EXPIRED");
            claimRefund();
          } else if (gameStatus === 6) {
            console.log(">>> GAME CANCELED");
            claimRefund();
          }

          contractEvents();
        }
        //TODO : Contract - oyun geçmişi ve birden çok oyuna join olma durumuna bakılacak
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    wallet.accounts,
    contractAndAuth.contract,
    gameId,
    cardCount,
    reqId,
    gameStatus,
  ]);
  return (
    <>
      <Link to={search} class="logo-game">
        <img src={logo} alt="" />
      </Link>
      {/* ConnectWallet2 veya ConnectWallet hangisi kullanılacak emin değilim */}
      <ConnectWallet2 />
      <div className="wrapper">
        <LeftLine
          headNumbers={headNumbers}
          drawnNumbers={drawnNumbers.sort()}
          passiveNumbers={passiveNumbers.sort()}
        />
        <div className="item-center">
          {/* <div onClick={() => setShowWinnerLoserPopup(true)}>winloser</div> */}
          <TopInfo game={game} />
          <Cards
            game={game}
            gameStatus={gameStatus}
            wallet={wallet}
            contractAndAuth={contractAndAuth}
            drawnNumbers={drawnNumbers}
            hostDelay={hostDelay}
            lastRevealedNumber={lastRevealedNumber}
            cardChangePopup={cardChangePopup}
            setCardChangePopup={setCardChangePopup}
          />
          <BottomButtons
            game={game}
            gameStatus={gameStatus}
            wallet={wallet}
            contractAndAuth={contractAndAuth}
            drawnNumbers={drawnNumbers}
            setCardChangePopup={setCardChangePopup}
            prizesClaimed={prizesClaimed}
          />
        </div>
        <RightLine />
      </div>

      {prizePopupParams.length > 0 && (
        <PrizePopup
          gameId={gameId}
          game={game}
          contractAndAuth={contractAndAuth}
          wallet={wallet}
          drawnNumbers={drawnNumbers}
          prizePopupParams={prizePopupParams}
          onClose={() => setPrizePopupParams([])}
        />
      )}

      {/* win props => true winners popup, false => losers popup */}
      {showWinnerLoserPopup && (
        <WinnerLoserPopup
          gameId={gameId}
          contractAndAuth={contractAndAuth}
          wallet={wallet}
          drawnNumbers={drawnNumbers}
          win={false}
          show={showWinnerLoserPopup}
          onClose={() => setShowWinnerLoserPopup(false)}
        />
      )}
    </>
  );
}
export default Game;
