import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'
import 'overlayscrollbars/overlayscrollbars.css';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { MetaMaskContextProvider } from "./utility/hooks/useMetaMask";
import { MetaMaskError } from "./components/MetaMaskError";
import Register from "./components/Register/Register";

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <BrowserRouter>
        <Provider store={store}>
          <Suspense>
            <AbilityContext.Provider value={ability}>
              <MetaMaskContextProvider>
                <App />
                <Register />
                <MetaMaskError/>
              </MetaMaskContextProvider>
            </AbilityContext.Provider>
          </Suspense>
        </Provider>
    </BrowserRouter>
);

reportWebVitals();