import React from "react";
import "./Warning.css";
import CloseButton from "../CloseButton/CloseButton";
import { Link } from "react-router-dom";
//images
import warnPopupImg from "../../assets/img/warnpopup.png"
import warnTitleImg from "../../assets/img/warntitle.png"

const Warning = (props) => {
  return (
    <>
      {props.show ? (
        <div className="popup-warning popup">
          <CloseButton onClose={props.onClose} />
          <div className="in">
            <img src={warnPopupImg} className="w-100" alt="" />
            <p className="title">
              <span>{props.title}</span>
              <img src={warnTitleImg} className="" alt="" />
            </p>
            <p className="text">{props.text}</p>
          </div>
          <div className="btns-bottom">
            <Link onClick={props.onClose} className="btn-sub">
              Close
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Warning;
