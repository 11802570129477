// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/join-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat !important;
  background-size: 110% !important;
}
.logo-lobby {
    position: absolute;
    left: -20px;
    top: 0;
    width: 100%;
  }
  
  .logo-lobby img {
    width: 100%;
    max-width: 360px;
  }
  
.lobby-carousel-area{
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    padding: 0 5%;
    width: 100%;
  }
  .lobby-carousel-area .time {
    position: absolute;
    font-size: 32px;
    left: 0;
    text-align: center;
    width: 100%;
    top: 8%;
    text-shadow: 0px 0px 13.2712px #fe4848, 0px 0px 36.155px #fe4848,
      0px 0px 21.0904px #fe4848, 0px 0px 10.5452px #fe4848,
      0px 0px 3.01291px #fe4848, 0px 0px 1.50646px #fe4848;
  }
  
  .lobby-carousel-area .game-number {
    text-shadow: 0px 0px 48.1507px #00deff, 0px 0px 27.5147px #00deff,
      0px 0px 16.0502px #00deff, 0px 0px 8.02512px #00deff,
      0px 0px 2.29289px #00deff, 0px 0px 1.14645px #00deff;
    font-size: 25px;
    position: absolute;
    top: 56%;
    text-align: center;
    width: 100%;
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/pages/Lobby/index.css"],"names":[],"mappings":"AAAA;EACE,wEAAkE;EAClE,gCAAgC;AAClC;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,WAAW;EACb;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;AAEF;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,aAAa;IACb,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,OAAO;IACP,kBAAkB;IAClB,WAAW;IACX,OAAO;IACP;;0DAEsD;EACxD;;EAEA;IACE;;0DAEsD;IACtD,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,kBAAkB;IAClB,WAAW;EACb","sourcesContent":["body {\n  background: url(../../assets/img/join-bg.jpg) no-repeat !important;\n  background-size: 110% !important;\n}\n.logo-lobby {\n    position: absolute;\n    left: -20px;\n    top: 0;\n    width: 100%;\n  }\n  \n  .logo-lobby img {\n    width: 100%;\n    max-width: 360px;\n  }\n  \n.lobby-carousel-area{\n    position: absolute;\n    left: 50%;\n    top: 45%;\n    transform: translate(-50%, -50%);\n    padding: 0 5%;\n    width: 100%;\n  }\n  .lobby-carousel-area .time {\n    position: absolute;\n    font-size: 32px;\n    left: 0;\n    text-align: center;\n    width: 100%;\n    top: 8%;\n    text-shadow: 0px 0px 13.2712px #fe4848, 0px 0px 36.155px #fe4848,\n      0px 0px 21.0904px #fe4848, 0px 0px 10.5452px #fe4848,\n      0px 0px 3.01291px #fe4848, 0px 0px 1.50646px #fe4848;\n  }\n  \n  .lobby-carousel-area .game-number {\n    text-shadow: 0px 0px 48.1507px #00deff, 0px 0px 27.5147px #00deff,\n      0px 0px 16.0502px #00deff, 0px 0px 8.02512px #00deff,\n      0px 0px 2.29289px #00deff, 0px 0px 1.14645px #00deff;\n    font-size: 25px;\n    position: absolute;\n    top: 56%;\n    text-align: center;\n    width: 100%;\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
