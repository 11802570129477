import { Link } from "react-router-dom";
import { useMetaMask } from "../utility/hooks/useMetaMask";
import { formatAddress } from "../utility/Utils";

const ConnectWallet = () => {
  const { wallet, hasProvider, connectMetaMask } = useMetaMask()

  return (
    <>
      {/* Navbar logo component
       <Link href="#" className="logo-join">
        <img src="img/logo2.svg" alt="" />
      </Link> */}
      {wallet.accounts.length < 1 &&
        <Link href="#" className="connect-head-btn" onClick={() => connectMetaMask()}>
          <img src="img/connect-btn.png" alt="" />
          <span>Connect Wallet</span>
        </Link>
      }

      {hasProvider && wallet.accounts.length > 0 &&
        <Link href="#" className="connect-head-btn">
          <img src="img/connect-btn.png" alt="" />
          <span
            id="disconn"
            style={{ fontSize: "12pt", textAlign: "center", cursor: "pointer" }}
          >
            JammyUser#1
            <br />
            {formatAddress(wallet.accounts[0])}
          </span>
        </Link>
      }
    </>
  )
}

export default ConnectWallet