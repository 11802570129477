// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-slide {
  transform: scale(0.85);
}
.slick-center {
  transform: scale(1.15) !important;
  cursor: pointer !important;
  transition: 0.4s ease-in-out;
}
.slick-slide img {
  width: 100%;
}
.slick-prev,
.slick-next {
  width: auto;
  height: auto;
  z-index: 10;
}
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/Carousel.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;AACA;EACE,iCAAiC;EACjC,0BAA0B;EAC1B,4BAA4B;AAC9B;AACA;EACE,WAAW;AACb;AACA;;EAEE,WAAW;EACX,YAAY;EACZ,WAAW;AACb","sourcesContent":[".slick-slide {\n  transform: scale(0.85);\n}\n.slick-center {\n  transform: scale(1.15) !important;\n  cursor: pointer !important;\n  transition: 0.4s ease-in-out;\n}\n.slick-slide img {\n  width: 100%;\n}\n.slick-prev,\n.slick-next {\n  width: auto;\n  height: auto;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
