import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import CloseButton from "../../CloseButton/CloseButton";
import { hexToArry } from "../../CreateCards";
import Loading from "../../Loading/Loading";
//images
import logo from "../../../assets/img/logo.svg";
import profileImg from "../../../assets/img/profil-image.png";
import continuesBgImg from "../../../assets/img/continues-bg.png";

const Prizes = ({
  gameId,
  game,
  contractAndAuth,
  wallet,
  drawnNumbers,
  prizePopupParams,
  onClose,
}) => {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [seconds, setSeconds] = useState(5);
  const [winCards, setWinCards] = useState([]);

  const claimPrize = async (claimGameId) => {
    try {
      if (typeof ethereum !== "undefined") {
        const isPrizesClaimed = await contractAndAuth.contract.prizesClaimed(
          claimGameId
        );
        console.log("isPrizesClaimed", isPrizesClaimed);
        if (!isPrizesClaimed) {
          const tx = await contractAndAuth.contract.claimPrize(claimGameId);
          const receipt = await tx.wait();
          console.log("receipt-winPrize:", receipt);
        } else {
          console.log("Game Prize Claimed");
        }
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkWinCards = async () => {
    // not: prizePopupParams[0]-> prizeIndex, prizePopupParams[1]-> winners
    let winnerCards = [];
    for (let i = 0; i < prizePopupParams[1].length; i++) {
      const winner = prizePopupParams[1][i].toLowerCase();
      console.log("winner:", i, winner);

      for (let k = 0; k < 4; k++) {
        //k playercardindex
        try {
          const playerCardHex = await contractAndAuth.contract.playerCards(
            game[0],
            winner,
            k
          );
          const playerCard = hexToArry(playerCardHex);
          const rowsToMatch = 5 - prizePopupParams[0];
          let matchedRows = 0;

          let seenNum = 0;
          let rowIndex = 0;
          let winRowIndex = 0;
          playerCard.forEach((cardNum, cardNumIndex) => {
            // console.log(k, "-cardNum:", cardNum);
            if (cardNum === 0) seenNum++;
            drawnNumbers.forEach((drawnNum) => {
              //TODO: includes kullan
              if (Number(cardNum) === Number(drawnNum)) {
                seenNum++;
              }
            });
            if (cardNumIndex % 5 === 4) {
              // console.log("playercardindex:", k,"rowIndex:", rowIndex, "seen:", seenNum);
              if (seenNum === 5) {
                matchedRows++;
                winRowIndex = rowIndex;
              }
              seenNum = 0;
              rowIndex++;
            }
          });

          if (matchedRows >= rowsToMatch) {
            console.log("winplayercardindex:", k, "winRowIndex:", winRowIndex);
            //TODO: Kart uzunluğu apiden alınacak (200)
            let cardIndex = 0;
            for (cardIndex; cardIndex < 200; cardIndex++) {
              const cards = await contractAndAuth.contract.cards(cardIndex);
              if (cards._hex === playerCardHex._hex) {
                break;
              }
            }
            winnerCards.push([cardIndex, playerCard]);
            break; // ilgili kullancıya ait ilk jam yapan kartı bulunca cıkar. birden fazla kartı o jam i yapmış olabilir
            // matchedRows = 0;
            // winRowIndex = 0;
          }
        } catch (error) {
          console.log(error.reason);
          break;
        }
      }
    }

    console.log("winnerCards:", winnerCards);
    setWinCards(winnerCards);
    setIsLoading(false);
  };

  useEffect(() => {
    if (contractAndAuth.contract && prizePopupParams && drawnNumbers) {
      console.log("useEffect-> Prize (popup):", prizePopupParams);
      checkWinCards();
    }
  }, [contractAndAuth, prizePopupParams, drawnNumbers]);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds === 0) {
        clearInterval(countdown);
        if (prizePopupParams.length > 0 && prizePopupParams[0] !== 0) {
          // onClose();
        }
        return;
      }
      setSeconds(seconds - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [seconds, prizePopupParams]);

  return (
    <div className="popup-wrapper continues-wrapper">
      <CloseButton onClose={onClose} to={search} />
      <div className="in">
        <div className="text-top-area continues-title">
          The
          {prizePopupParams.length > 0
            ? prizePopupParams[0] === 4
              ? " 1st Jam "
              : prizePopupParams[0] === 3
              ? " 2nd Jam "
              : prizePopupParams[0] === 2
              ? " 3rd Jam "
              : prizePopupParams[0] === 1
              ? " 4th Jam "
              : prizePopupParams[0] === 0
              ? " JAMMY "
              : null
            : null}
          Goes to
        </div>

        <div className="info-box-wrapper">
          {prizePopupParams.length > 0
            ? prizePopupParams[1].map((winneraddress, index) => (
                <div className="profile-info-box" key={index}>
                  <div className="img">
                    <img src={profileImg} alt="" />
                  </div>
                  <div className="right">
                    <span>Jammy User # {index}</span>
                    <small>Wallet: {winneraddress}</small>
                  </div>
                </div>
              ))
            : "No Params"}
        </div>

        <div className="card-bottom-area">
          <div className="card-container">
            {isLoading ? (
              <div className="item-card">
                <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                  <img src={logo} alt="" />
                  <Loading text="Loading card..." />
                </div>
              </div>
            ) : (
              winCards.length > 0 &&
              winCards.map((card, index) => ( //card[0]-> cardIndex, card[1]-> cardNumbers
                <Fragment key={index}>
                  <div className="item-card">
                    <span>{card[0]}</span>
                    <div className="in">
                      {card[1].map((numbers, numberindex) => (
                        <div
                          className={
                            drawnNumbers.includes(numbers) || numbers === 0
                              ? "item revealed"
                              : "item"
                          }
                          key={numberindex}
                        >
                          {numbers !== 0 ? numbers : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </Fragment>
              ))
            )}
            <div className="right-area">
              {prizePopupParams.length > 0 && prizePopupParams[0] !== 0 ? (
                <div className="text">
                  <span>The Game Continues!</span>
                  <small>Roll up your sleeves to be the next!</small>
                </div>
              ) : (
                <div className="text">
                  <span>Buddy, The Game is Over!</span>
                  <small>Let's see the bucks for the new game!</small>
                  <Link
                    to={search}
                    className="right-btn-o"
                    onClick={() => claimPrize(game[0])}
                  >
                    {" "}
                    OKAY
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <img src={continuesBgImg} className="popup-bg" alt="" />
      </div>
    </div>
  );
};

export default Prizes;
